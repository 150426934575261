<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div
  class="backHeader"
  fxLayout="row"
  fxLayout.xs="column"
  fxLayoutAlign="start stretch"
>
  <mat-label>
    <button mat-icon-button (click)="navigateToBatchTransactions()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Back to Batch Transactions
  </mat-label>
</div>
<form [formGroup]="updateBatchFormGroup">
  <div fxLayout="column" fxLayoutGap="16px">
    <mat-card class="totalTransactionsDiv" fxFlex="100%">
      <div fxLayout="row" fxLayoutGap="16px" fxFlex="100%">
        <div
          fxLayoutGap="10px"
          fxFlex="100%"
          class="header-content"
          fxLayoutAlign="start center"
        >
          <span class="header-title">Company Name: </span>
          {{ this.companyName }}
          <span class="header-title" style="padding-left: 50px"
            >Company ID:</span
          >{{ this.companyId }}
        </div>
        <!-- <span class="spacer"></span> -->
        <div fxLayout="column" fxLayoutAlign="end end">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutGap="10px"
            fxFlex="100%"
          >
            <button
              class="white-button-style"
              appearance="outline"
              (click)="navigateToBatchTransactions()"
              mat-button
            >
              <span>CANCEL</span>
            </button>
            &nbsp;
            <button
              mat-raised-button
              color="primary"
              (click)="updateBatch('NEW')"
              class="page-header-button-style"
              [disabled]="updateBatchFormGroup.invalid"
            >
              SAVE
            </button>
            &nbsp;
            <button
              mat-raised-button
              color="primary"
              (click)="updateBatch('PENDING')"
              class="page-header-button-style"
              [disabled]="updateBatchFormGroup.invalid"
            >
              SAVE & QUEUE
            </button>
          </div>
        </div>
      </div>
    </mat-card>
    <mat-card class="totalTransactionsDiv" fxFlex="100%">
      <div fxLayout="row" fxLayoutGap="16px" fxFlex="100%">
        <mat-form-field fxFlex="25%" appearance="outline">
          <mat-label>Customer Group</mat-label>
          <input
            matInput
            [(ngModel)]="groupName"
            formControlName="groupName"
            [disabled]="isDisabled"
          />
        </mat-form-field>
        <mat-form-field fxFlex="25%" appearance="outline">
          <mat-label> Transaction Type</mat-label>
          <mat-select
            class="form-control"
            [(value)]="transactionType"
            formControlName="transactionType"
            [disabled]="isDisabled"
          >
            <mat-option
              *ngFor="let type of transactionTypes"
              [value]="type.value"
            >
              {{ type.displayName }}
            </mat-option>
          </mat-select>
          <!-- <mat-error>
            {{ applyToAllForm.controls["transactionTypeApplyAll"]["_errorMessage"] }}
          </mat-error> -->
        </mat-form-field>

        <mat-form-field fxFlex="25%" appearance="outline">
          <mat-label>Batch Name</mat-label>
          <input matInput [(ngModel)]="batchName" formControlName="batchName" />
        </mat-form-field>

        <mat-form-field fxFlex="25%" appearance="outline">
          <mat-label>Effective Date</mat-label>
          <input
            matInput
            [matDatepicker]="picker"
            class="form-control"
            formControlName="effectiveDate"
            placeholder="Select Effective Date"
            autocomplete="off"
            [min]="minEffectiveDate"
            (dateChange)="changeEffectiveDate()"
            readonly
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>
            {{
              updateBatchFormGroup.controls["effectiveDate"]["_errorMessage"]
            }}
          </mat-error>
        </mat-form-field>
      </div>
    </mat-card>
    <div fxLayout="row">
      <mat-card
        class="totalTransactionsDiv custom-mat-card"
        style="padding: 0px"
        fxFlex="100%"
      >
        <mat-card-header class="mat-card-header">
          <div fxLayoutGap="16px" fxFlex="100%" class="header-content">
            <span style="padding-top: 9px"
              >TOTAL # OF TRANSACTIONS:<span class="transaction-header-title">
                {{ getTotalTransactions }}</span
              >
            </span>
            <span style="padding-top: 9px"
              >TOTAL DEBITS:<span class="transaction-header-title">
                ${{ getTotalDebits | number : "1.2-2" }}</span
              >
            </span>
            <span style="padding-top: 9px"
              >TOTAL CREDITS:<span class="transaction-header-title">
                ${{ getTotalCredits | number : "1.2-2" }}</span
              >
            </span>
            <span style="display: flex; align-items: center">
              NEXT CUTOFF TIME:
              <span
                style="font-weight: bold; margin-left: 7px; text-align: center"
              >
                <div>{{ nextCutOffTime | date : "MM/dd/yyyy" }}</div>
                <div style="margin-left: 10px">
                  {{ nextCutOffTime | date : "hh:mm a" }} PST
                </div>
              </span>
            </span>
          </div>
        </mat-card-header>
        <mat-card-content>
          <h3 style="padding: 16px; padding-bottom: 0px; margin-bottom: 0px">
            Batch
            <span style="cursor: pointer" (click)="$event.stopPropagation()">
              <img
                matTooltip="Add new customer"
                class="sectionSubImg person-image"
                (click)="openCustomerDialog()"
                src="assets/grid-icons/addCustomer.png"
              />
            </span>
            <a
              (click)="openApplyToAllDialog()"
              style="color: blue; cursor: pointer; float: right"
              >Apply to All</a
            >
          </h3>
          <app-data-table
            [columns]="customerGrpscolumn"
            [data]="data"
            [isLoading]="isLoading"
            [noRecordMessage]="noRecordMessage"
            [allColumns]="allColumns"
            [isExport]="false"
            [role]="this.userRole"
            [page]="page"
            [isFilter]="false"
            [isSearch]="false"
            [datatablemargin]="this.datatablemargin"
            (pageChangedEvent)="onPageChanged($event)"
            [sortBy]="sortBy"
            (sortChangedEvent)="onSortChanged($event)"
            [isViewBatch]="false"
            [transactionType]="transactionType"
            [transactionTypes]="transactionTypesGrid"
            (batchViewEvent) = "openViewNoteDialog($event)"
            [isBatch]="true"
          >
          </app-data-table>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</form>
<ng-template #applyToAllDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Apply to All</b></h4>
  <form [formGroup]="applyToAllForm">
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="form-field-bottom"
    >
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>ACH Transaction Type</mat-label>
        <mat-select
          class="form-control"
          formControlName="transactionType"
          name="transactionType"
        >
          <mat-option
            *ngFor="let type of transactionTypesGrid"
            [value]="type.value"
          >
            {{ type.displayName }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{ applyToAllForm.controls["transactionType"]["_errorMessage"] }}
        </mat-error>
      </mat-form-field>
      <span style="align-items: center">
        <img
          class="form-field-search"
          matTooltip="doller"
          src="assets/grid-icons/doller.png"
        />&nbsp;&nbsp;
        <mat-form-field fxFlex="90%" appearance="outline">
          <mat-label>Default Amount</mat-label>
          <input
            matInput
            class="form-control"
            formControlName="defaultAmount"
            type="number"
            name="defaultAmount"
            placholder="Enter Default Amount"
            autocomplete="off"
            min="0"
            appTwoDigitDecimalNumber
          />
          <mat-error>
            {{ applyToAllForm.controls["defaultAmount"]["_errorMessage"] }}
          </mat-error>
        </mat-form-field>
      </span>
    </div>

    <div mat-dialog-actions fxLayoutAlign="end end">
      <button
        class="white-button-style"
        appearance="outline"
        (click)="onCancel()"
        mat-button
      >
        <span>CANCEL</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="applyToAllForm.invalid"
        class="page-header-button-style"
        (click)="onApply()"
      >
        Apply
      </button>
    </div>
  </form>
</ng-template>

<ng-template #addCustomerDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Add Customer</b></h4>
  <form [formGroup]="customerSearchFormGroup">
    <div
      fxLayout="row"
      fxLayoutGap="16px"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="form-field-bottom"
    >
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="customerName"
          [matAutocomplete]="auto"
          placeholder="Customer Name (min 3 characters)"
          pattern="^[a-zA-Z ]*$"
          style="padding-bottom: 1%"
        />
        <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSelection()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button matPrefix mat-icon-button class="search-margin">
          <mat-icon>search</mat-icon>
        </button>
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelected()"
        >
          <mat-option *ngIf="isLoading" class="is-loading"
            >Loading...</mat-option
          >
          <ng-container *ngIf="!isLoading && filteredCustomer.length > 0">
            <mat-option
              *ngFor="let customer of filteredCustomer"
              [value]="selectedCustomerName(customer)"
            >
              <div class="name-email-style">
                {{ customer.firstName?.trim() }}&nbsp;{{
                  customer.lastName?.trim()
                }}
              </div>
              <div class="name-email-style sub-text">
                {{ customer.email }}
              </div>
            </mat-option>
          </ng-container>
          <ng-container
            *ngIf="isShowNoFound && !isLoading && !filteredCustomer.length"
          >
            <mat-option>
              <div class="name-email-style">
                <b>No Records Found</b>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>
      <span (click)="$event.stopPropagation()" fxLayoutAlign="center center">
        <img
          matTooltip="Add new customer"
          class="sectionSubImg person-image"
          (click)="openNewCustomerDialog()"
          src="assets/grid-icons/addCustomer.png"
        />
      </span>
    </div>

    <div mat-dialog-actions fxLayoutAlign="center center">
      <button
        class="white-button-style"
        appearance="outline"
        (click)="onCancel()"
        mat-button
      >
        <span>CANCEL</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="!customerSearchFormGroup.get('customerId').value"
        class="page-header-button-style"
        (click)="insertSelectedCustomer()"
      >
        INSERT
      </button>
    </div>
  </form>
</ng-template>

<ng-template #viewNoteDialog>
  <h6 mat-dialog-title fxLayoutAlign="center end"><b>Save Note</b></h6>
  <form [formGroup]="updateNoteForm">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
    class="description-top">
    <div fxLayout="column" fxFlex="100%" >      
        <div class="textarea-spacing">
            <mat-form-field appearance="outline">  
              <input type="hidden" id="customerId" formControlName="customerId">            
              <textarea
                autosize
                matInput
                name="description"
                rows="5"
                formControlName="description"
              >
              </textarea>
            </mat-form-field>
        </div> 
    </div>              
</div> 

    <div mat-dialog-actions fxLayoutAlign="end end">
      <button
        class="white-button-style"
        appearance="outline"
        mat-button
        [mat-dialog-close]="true"
      >
        <span>CANCEL</span>
      </button>
      <button
      mat-raised-button
      color="primary"
      [disabled]="updateNoteForm.invalid"
      class="page-header-button-style"
      (click)="updateNote()">
      SAVE
     </button>
    </div>
  </form>
</ng-template>
