import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CrudService } from '../../sharedModules/services/http/crud.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';

@Injectable({
  providedIn: 'root',
})

export class CustomerService extends CrudService<any, any> {

  constructor(protected override _http: HttpClient) {
    super(_http, `${environment.API_BASE_URL_CUSTOMER}Customer`);
  }

  getCustomerById(customerId: any): Observable<any> {
    return this._http.get<any>(this._base + "/getCustomerById/" + customerId);
  }

  GetAllCustomers(filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
    let baseUrl = this._base + "/getAllCustomers?"
    baseUrl = sortBy !== undefined ? baseUrl + "sortBy=" + sortBy : baseUrl;
    baseUrl = baseUrl + "&sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;    
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  deactivateCustomer(customerAndStatus: any): Observable<any> {
    return this._http.put<any>(this._base + "/deactivateCustomer", customerAndStatus);
  }

  AddCustomerBasicDetails(model: AddCustomerModel): Observable<any> {
    return this._http.post<any>(this._base + "/addCustomer", model);
  }

  EditCustomerBasicDetails(customerId: any, model: AddCustomerModel): Observable<any> {
    return this._http.put<any>(this._base + "/updateCustomer/" + customerId, model);
  }

  SearchCustomers(searchTerm: string,isActive:boolean): Observable<any> {    
    return this._http.get<any>(this._base + "/searchCustomers/" + searchTerm + "/"+isActive);
  }
  getAllActiveCustomers(filterBy: string, sortBy: string, sortDirection: string,
    page: number, pageSize: number, advanceFilters: string): Observable<any> {
      let baseUrl = this._base + "/GetAllActiveCustomers?"
    baseUrl = sortBy !== undefined ? baseUrl + "sortBy=" + sortBy : baseUrl;
    baseUrl = baseUrl + "&sortDirection=" + sortDirection + "&page=" + page + "&pageSize=" + pageSize;    
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }

  getAllActiveCustomersWithoutPage(filterBy: string, sortBy: string, sortDirection: string,
     advanceFilters: string): Observable<any> {
      let baseUrl = this._base + "/GetAllActiveCustomers?"
    baseUrl = sortBy !== undefined ? baseUrl + "sortBy=" + sortBy : baseUrl;
    baseUrl = baseUrl + "&sortDirection=" + sortDirection;    
    baseUrl = filterBy ? baseUrl + "&filterBy=" + filterBy : baseUrl;    
    baseUrl = advanceFilters ? baseUrl + "&advanceFilters=" + advanceFilters : baseUrl;
    return this._http.get<any>(baseUrl);
  }
}
