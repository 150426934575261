import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BatchTransactionsService } from 'src/app/blockModules/services/batch-transactions.service';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import { PageChanged, SortChanged } from 'src/app/sharedModules/models/pagination.model';
@Component({
  selector: 'app-view-batch-transactions',
  templateUrl: './view-batch-transactions.component.html',
  styleUrls: ['./view-batch-transactions.component.scss'],
})
export class ViewBatchTransactionsComponent implements OnInit {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait ....';
  groupName: string = '';
  transactionType: string = '';
  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTOMER', name: 'name', width: '10%' },
    { displayName: 'ID', name: 'customerId', width: '30%' },
    { displayName: 'ACCT #', name: 'accountNumber', width: '8%' },
    { displayName: 'TYPE', name: 'achTransactionType', width: '8%' },
    { displayName: 'AMOUNT', name: 'defaultAmount', width: '14%' },
    { displayName: 'STATUS', name: 'batchTransactionStatus', width: '10%' },
    { displayName: 'NOTES', name: 'notes', width: '10%' },
    { displayName: 'INCLUDE', name: 'isInclude', width: '10%' },
  ];
  data: any;
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };
  datatablemargin: string = '0px';
  batchId: string;
  selectedCustomerList: any[] = [];
  companyName: string = '';
  companyId: string = '';
  batchName: string = '';
  note: string = '';
  effectiveDate: Date = new Date();
  nextCutOffTime: Date = new Date();
  isBatchModify : boolean;
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  applyToAllForm: FormGroup;
  viewNoteForm : FormGroup;
  @ViewChild('applyToAllDialog') applyToAllDialog!: TemplateRef<any>;
  @ViewChild('viewNoteDialog') viewNoteDialog!: TemplateRef<any>;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private fb: FormBuilder,
    private customerGroupService: BatchTransactionsService,
    private _toastrService: ToastrService,
    private dialog: MatDialog
  ) {
    this.allColumns = this.customerGrpscolumn;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
    this.companyId = localStorage.getItem('CompanyId') || "";
    this.companyName =  localStorage.getItem('CompanyName') || "";
  }

  transactionTypes = [
    { value: 'CREDIT', displayName: 'Credit Only' },
    { value: 'DEBIT', displayName: 'Debit Only' },
    { value: 'CREDIT & DEBIT', displayName: 'Credit & Debit' },
  ];

  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.batchId = params.get('batchId');
    });
    this.loadData();
    this.viewNoteForm = this.fb.group({
      description: ['']
    });

  }
  navigateToBatchTransactions() {
    this.router.navigate(['batchTransactions']);
  }

  loadData() {
    if (this.batchId) {
      this.getAllCustomerByBatchId(this.batchId);
    }
  }
  GetTransactionType(type : string){
    let result = this.transactionTypes.find((obj) => obj.value === type);
    return result?.displayName || '';
  }
  getAllCustomerByBatchId(batchId: any) {
    this.isLoading = true;
    this.customerGroupService
      .getAllCustomerByBatchId(batchId)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.data != null) {
          this.groupName = response.data.customerGroupName;
          this.transactionType = this.GetTransactionType(response.data.groupTransactionType.toUpperCase());
          this.batchName = response.data.batchName;
          this.effectiveDate = response.data.effectiveDate;
          this.nextCutOffTime = response.data.nextCutOffTime; 
          this.isBatchModify = response.data.isActive && response.data.batchStatus.toLowerCase() == 'new';
          this.data = response.data.batchTransactionDetails;
          this.selectedCustomerList = this.data;
          this.page.length = this.data.length;
          this.onPageChanged(this.page);
        } else {
          this.data = [];
          this._toastrService.error(response.errorMessage);
        }
      }),
      (error) => {
        this.data = [];
        this.isLoading = false;
        this._toastrService.error(error.errorMessage);
      };
  }

  batchEdit() {
    this.router.navigate(['add-batch-transactions', this.batchId]);
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize ||
      this.groupName != ''
    ) {
      this.page = event;
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      this.data = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  openApplyToAllDialog() {
    const dialogRef = this.dialog.open(this.applyToAllDialog);

    dialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog result:', result);
    });
  }

  onCancel() {
    this.dialog.closeAll();
  }
  onApply() {
    // const transactionType = this.applyToAllForm.get('transactionTypeApplyAll')?.value;
    // const defaultAmount = this.applyToAllForm.get('defaultAmount')?.value;
    // this.selectedCustomer.forEach(customer => {
    //   customer.AchTransactionType = transactionType;
    //   customer.defaultAmtText = defaultAmount;
    // });
    // this.selectedCustomer = [...this.selectedCustomer, ...this.unselectedCustomers.filter(c => c.isSelected)];
    // this.updateCustomerGrpFormGroup.patchValue({
    //   AchTransactionType: transactionType || ''
    // });
    // this.dialog.closeAll();
    // this.selectedCustomerList = this.selectedCustomer;
    // this.pageSelected.length = this.selectedCustomer.length;
  }
  openCustomerDialog() {
    // const dialogRef = this.dialog.open( {
    //   height: '520px',
    //   width: '700px',
    //   data: {
    //     buttonName: 'ACCEPT',
    //     actionButton: true,
    //   },
    //   autoFocus: false,
    // });
    // dialogRef.afterClosed().subscribe((confirmed: boolean) => {
    // });
  }

  get getTotalTransactions() {
    return this.data?.length || 0;
  }

  get getTotalDebits() {
    return this.data?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'debit')
                   .reduce((sum, record) => sum + record.defaultAmount, 0) || 0;
  }

  get getTotalCredits() {
    return this.data?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'credit')
                   .reduce((sum, record) => sum + record.defaultAmount, 0) || 0;
  }

  onSortChanged(event: SortChanged) {
    if (this.sortBy.active !== event.active || this.sortBy.direction !== event.direction) {
        this.sortBy = event;

        if (this.data) {
            const { active, direction } = this.sortBy;

            const sortedList = [...this.data].sort((a, b) => {
                let valueA = a[active];
                let valueB = b[active];

                if (active === 'name') {
                    valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
                    valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
                }

                if (valueA === undefined || valueA === null || valueA === '') return direction === 'asc' ? -1 : 1;
                if (valueB === undefined || valueB === null || valueB === '') return direction === 'asc' ? 1 : -1;

                if (typeof valueA === 'string' && typeof valueB === 'string') {
                    return direction === 'asc' ? valueA.localeCompare(valueB) : valueB.localeCompare(valueA);
                } else if (typeof valueA === 'number' && typeof valueB === 'number') {
                    return direction === 'asc' ? valueA - valueB : valueB - valueA;
                } else {
                    const strA = String(valueA);
                    const strB = String(valueB);
                    return direction === 'asc' ? strA.localeCompare(strB) : strB.localeCompare(strA);
                }
            });

            this.data = sortedList;
        }
    }
  }
  openViewNoteDialog(data : any){
    let customerRecord = this.data?.find(record => record.customerId == data);
    if(customerRecord ){
     this.note = customerRecord.notes;
    const viewNotedialogRef = this.dialog.open(this.viewNoteDialog,{
      height: '280px',
      width: '350px',
    });

    viewNotedialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog result:', result);
    });
  }
  }
}
