import { AbstractControl } from '@angular/forms';
import { required, prop, maxLength, pattern, minLength } from '@rxweb/reactive-form-validators';

export class AddCustomerBankDetailsModel {
  @required()
  customerId: string;

  @prop()
  customerBankAccountId : any;

  @prop()
  @required()
  @maxLength({ value: 10 })
  @pattern({ expression: { 'Alpha_Hyphen_Period_Space': /^[a-zA-Z.\-\s]*$/ } })
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  accountType: string;

  @prop()
  @maxLength({ value: 200 })
  bankName: string;

  @prop()
  @required()
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Number': /^[0-9]*$/ } })
  @maxLength({ value: 9, message : 'Routing number should be 9 digits'})
  @minLength({ value: 9, message : 'Routing number should be 9 digits'})
  routingNumber: string;

  @prop()
  @required()
  @pattern({ expression: { 'No_Space': /.*\S.*/ } })
  @pattern({ expression: { 'Number': /^[0-9]*$/ } })
  @minLength({ value: 6 })
  @maxLength({ value: 17 })
  bankAccountNumber: number;

  @prop()
  status: boolean=true;

  @prop()
  isPrimary: boolean = false;
}
