import {
  AfterViewInit,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CreditDebitTransactionModel } from 'src/app/featureModules/models/addcreditdebit-transaction.model';
import { CreditDebitTransactionService } from 'src/app/featureModules/services/creditDebit-transaction.service';
import { CustomerCardDetailsService } from 'src/app/featureModules/services/customer-card-details.service';
import { CustomerService } from 'src/app/blockModules/services/customers.service';
import { MatStepper } from '@angular/material/stepper';
import { AddCustomerModel } from 'src/app/sharedModules/models/add-customer.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { catchError, map, of } from 'rxjs';
import creditCardType, { types as CardType } from 'credit-card-type';
import { MatCalendarCellClassFunction } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { SurchargeDialogComponent } from 'src/app/externalModules/paymentWidget/dialogs/surcharge-dialog/surcharge-dialog.component';
import { environment } from 'src/environments/environment';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';

interface DrowpDown {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-add-creditdebit-transaction',
  templateUrl: './add-creditdebit-transaction.component.html',
  styleUrls: ['./add-creditdebit-transaction.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AddCreditdebitTransactionComponent
  implements OnInit, AfterViewInit
{
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;

  isLoading: boolean = false;
  cardList = [];

  selectedValue: string;
  isRecurring: boolean = false;
  model: CreditDebitTransactionModel;
  customerCardId;
  customerId;
  processorId;
  processors: DrowpDown[] = [
    { value: 'NMI Payment Processor', viewValue: 'NMI Payment Processor' },
  ];
  frequencies: DrowpDown[];
  customerModel: AddCustomerModel;
  existingCustomer: boolean = false;
  userName: string = '';
  userEmail: string = '';
  todaydate = new Date();
  minDate = new Date();
  maxDate = new Date();
  defaultScheduledDate = new Date();
  cardType: string = '';

  amountModel: CreditDebitTransactionModel;
  @ViewChild('stepper') stepper: MatStepper;
  processorName: string = '';
  selectedCardName: string = '';

  //routing params
  previousUrl: string = '';
  selectedTransactionTabIndex: number;
  transactionIndex: number;
  tabIndex: number;

  ngAfterViewInit() {
    if (this.existingCustomer) {
      this.firstFormGroup.controls['firstCtrl'].setValue('test');
      setTimeout(() => {
        this.stepper.selectedIndex = 1;
      });
    }
  }

  constructor(
    private _formBuilder: FormBuilder,
    private formBuilder: RxFormBuilder,
    private router: Router,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private _customerCardDetailsService: CustomerCardDetailsService,
    private _toastrService: ToastrService,
    private _creditDebitTransaction: CreditDebitTransactionService,
    private _customerService: CustomerService,    
  ) {
    const navigation = this.router.getCurrentNavigation();
    this.previousUrl = navigation?.extras?.state
      ? navigation.extras.state['url']
      : '';

    this.selectedTransactionTabIndex = navigation?.extras?.state
      ? navigation.extras.state['selectedTransactionTabIndex']
      : 0;

    this.tabIndex = navigation?.extras?.state
      ? navigation.extras.state['tabIndex']
      : 0;

    this.transactionIndex = navigation?.extras?.state
      ? navigation.extras.state['transactionIndex']
      : 0;

    if (this.previousUrl != '')
      localStorage.setItem('previousUrl', this.previousUrl);
    else this.previousUrl = localStorage.getItem('previousUrl');
  }

  ngOnInit() {

    this.setFrequencyDropdown();
    this.activatedRoute.paramMap.subscribe((params) => {
      this.customerId = params.get('customerId');
    });
    if (this.customerId) {
      this.getCustomerById();
      this.existingCustomer = true;
    }
    this.firstFormGroup = this._formBuilder.group({
      firstCtrl: ['', Validators.required],
    });
    this.secondFormGroup = this._formBuilder.group({
      secondCtrl: ['', Validators.required],
      cvv: ['', [Validators.required ,Validators.pattern("^[0-9]*$"),Validators.minLength(3)]],
      processorCtrl: [null],
    });
    this.amountModel = new CreditDebitTransactionModel();
    this.thirdFormGroup = this.formBuilder.formGroup(this.amountModel);

    this.minDate.setDate(this.minDate.getDate() + 1);
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);   
  }

  setFrequencyDropdown(){
    let isDailyRecurringEnabled = `${environment.IS_DAILY_RECURRING_ENABLED}`;
    this.frequencies = (isDailyRecurringEnabled?.toLowerCase() == "true") ?
      [
        { value: 'DAILY', viewValue: 'Daily' },
        { value: 'WEEKLY', viewValue: 'Weekly' },
        { value: 'BIWEEKLY', viewValue: 'Bi-Weekly' },
        { value: 'MONTHLY', viewValue: 'Monthly' },
        { value: 'ANNUALLY', viewValue: 'Yearly' },
      ]
    :
      [
        { value: 'WEEKLY', viewValue: 'Weekly' },
        { value: 'BIWEEKLY', viewValue: 'Bi-Weekly' },
        { value: 'MONTHLY', viewValue: 'Monthly' },
        { value: 'ANNUALLY', viewValue: 'Yearly' },
      ];
  }

  get f(){
    return this.secondFormGroup.controls;
  }

  onFrequencyChanged(event: any){
    let isDailyRecurringEnabled = `${environment.IS_DAILY_RECURRING_ENABLED}`;
    
    this.defaultScheduledDate = new Date();
    this.maxDate = new Date();
    this.maxDate.setMonth(this.maxDate.getMonth() + 3);

    if (event == "DAILY" && (isDailyRecurringEnabled?.toLowerCase() == "true")){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 1);
    } else if (event == "WEEKLY"){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 7);
    } else if (event == "BIWEEKLY"){
      this.defaultScheduledDate.setDate(this.defaultScheduledDate.getDate() + 14);
    } else if (event == "MONTHLY"){
      this.defaultScheduledDate.setMonth(this.defaultScheduledDate.getMonth() + 1);
    } else if (event == "ANNUALLY"){
      this.defaultScheduledDate.setMonth(this.defaultScheduledDate.getMonth() + 12);
      this.maxDate = new Date(this.defaultScheduledDate); 
      this.maxDate.setMonth(this.maxDate.getMonth() + 3);
    }
    this.thirdFormGroup.controls['scheduledDate'].setValue(this.defaultScheduledDate);
  }

  navigateToTransactionsPage() {
    this.router.navigateByUrl(this.previousUrl, {
      state: {
        selectedTransactionTabIndex: this.selectedTransactionTabIndex,
        transactionIndex: this.transactionIndex,
        tabIndex: this.tabIndex,
      },
    });
  }

  onCustomerSelection(customer: any) {
    if (customer != null) {
      this.firstFormGroup.patchValue({ firstCtrl: customer.firstName });
      this.userName = customer.firstName;
      this.userName += customer.lastName ? ' ' + customer.lastName : '';
      this.userEmail = customer.email;
      this.getCardDetails(customer.customerId);
      this.secondFormGroup.patchValue({ secondCtrl: '', cvv : '',processorCtrl: null });
    } else {
      this.firstFormGroup.patchValue({ firstCtrl: '' });
      this.userName = '';
      this.userEmail = '';
    }
    this.processorName = '';
    this.selectedCardName = '';
  }

  getCardDetails(customerId: any) {
    this.isLoading = true;
    this.cardList = [];
    this._customerCardDetailsService.GetAllCards(customerId, true).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.cardList = data.data;
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  getCustomerById() {
    this.isLoading = true;
    this._customerService.getCustomerById(this.customerId).subscribe(
      (data) => {
        this.isLoading = false;
        if (data.data != null) {
          this.customerModel = data.data;
          this.userName = this.customerModel.firstName;
          this.userName += this.customerModel.lastName
            ? ' ' + this.customerModel.lastName
            : '';
          this.userEmail = this.customerModel.email;
          this.getCardDetails(this.customerId);
        }
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  onSubmit(){    
    var data = {
      cardId: this.customerCardId,
      amount: this.thirdFormGroup.controls['amount'].value,          
    };
    this._creditDebitTransaction.LookUpBin(data).subscribe(
    (data) => { 
      if(data.code != 400){     
      this.cardType = this.getCardType(data.data.cardType);      
        if (data.data.debit == 'N' && data.data.surchargePercent != 0) {
          this.openSurChargeDialog(data.data);
        } else {
          this.addCreditDebitTransaction(data.data);
        }
      }else{
        this._toastrService.error(data.errorMessage);
      }      
    },
    (error) => {      
      this._toastrService.error('Invalid Card Number');
    }
  ); 
  }

  openSurChargeDialog(data: any) {
    const dialogRef = this.dialog.open(SurchargeDialogComponent, {
      disableClose: true,    
      data: {
        title: 'Surcharge', 
        color: "#1565c0",       
        mainMessage:
          'For Credit Card Payments a Surcharge of ' +
          (data?.surchargePercent ?? 0) +
          ' % is applicable.',
        subMessage:
          'Clicking on agree button will apply surcharge fees on current total Amount.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {                        
        this.addCreditDebitTransaction(data);
      }
    });
  }

  addCreditDebitTransaction(data:any) {
    if (this.thirdFormGroup.controls['amount'].value <= 0) {
      this._toastrService.warning('Please enter amount greater than 0');
    } else {
      this.isLoading = true;
      this.model = new CreditDebitTransactionModel();
      this.model.customerId = this.customerId;
      this.model.customerCardId = this.customerCardId;
      this.model.cvv = this.secondFormGroup.controls['cvv'].value;
      //this.model.processorId = this.customerCardId;
      this.model.IsRecurring =
        this.thirdFormGroup.controls['IsRecurring'].value;
      this.model.installments = this.thirdFormGroup.controls['installments']
        .value
        ? this.thirdFormGroup.controls['installments'].value
        : 0;
      this.model.amount = parseFloat(this.thirdFormGroup.controls['amount'].value);
      this.model.payFrequency =
        this.thirdFormGroup.controls['payFrequency'].value;
        
      this.model.scheduledDate = getSafeIsoDateString(this.thirdFormGroup.controls['scheduledDate'].value
        ? this.thirdFormGroup.controls['scheduledDate'].value
        : new Date());  
      
      if (this.thirdFormGroup.valid) {
        this._creditDebitTransaction
          .CreateCardTransaction(this.model)
          .subscribe(
            (data) => {
              this.isLoading = false;
              if(data.errorMessage){
                this._toastrService.error(data.errorMessage);
              }
              else{
                this._toastrService.success(
                  'Transaction Details Added Successfully'
                );
              }            
              this.router.navigateByUrl(this.previousUrl, {
                state: {
                  selectedTransactionTabIndex: this.selectedTransactionTabIndex,
                  transactionIndex: this.transactionIndex,
                  tabIndex: this.tabIndex,
                },
              });
            },
            (error) => {
              this.isLoading = false;
            }
          );
      } else {
        this.isLoading = false;
        this._toastrService.warning('Please enter the required fields!');
      }
    }
  }

  onCardSelected(event: any, card: any) {  
    this.cardList.forEach((ob) => (ob.cardSelection = false));
    card.cardSelection = event.value;    
    this.customerCardId = card.customerCardId;
    this.customerId = card.customerId;
    this.secondFormGroup.patchValue({ secondCtrl: card.cardSelection });
    this.selectedCardName = this.getCardName(card.cardNumber);
  }

  recurringChecked(event) {    
    this.isRecurring = event.checked;
  }  

  calculateTotalAmount() {
    if (this.thirdFormGroup.controls['installments'].value) {
      let totalAmount =
        parseFloat(this.thirdFormGroup.controls['amount'].value) *
        parseFloat(this.thirdFormGroup.controls['installments'].value);
      this.thirdFormGroup.controls['totalAmount'].setValue(
        totalAmount.toFixed(2).toString()
      );
    }
    else{
      this.thirdFormGroup.controls['totalAmount'].setValue("");
    }
  }

  cardNumberEncreption(cardNumber: any) {
    if (cardNumber) {
      cardNumber = '**** **** **** ' + cardNumber.slice(cardNumber.length - 4);
    }
    return cardNumber;
  }

  getCardName(cardNumber: any) {
    let cardName = 'Customer Card';
    if (cardNumber) {
      var visaCards = creditCardType(cardNumber);
      if (visaCards && visaCards[0] && visaCards[0].niceType)
        cardName = visaCards[0].niceType;
    }
    return cardName;
  }

  getCardImage(cardNumber: any) {
    let cardImageName = 'assets/grid-icons/default-card.png';
    if (cardNumber) {
      var visaCards = creditCardType(cardNumber);
      if (visaCards && visaCards[0] && visaCards[0].type)
        cardImageName = 'assets/grid-icons/' + visaCards[0].type + '.png';
    }
    return cardImageName;
  }

  processorSelection(value) {
    this.processorName = value;
    this.secondFormGroup.patchValue({ processorCtrl: value });
  }

  getCardType(cardType: string) {
    switch (cardType.toLowerCase()) {
      case 'mastercard':
      case 'discover':
      case 'unknown':
      case 'americanexpress':
      case 'visa':
        return cardType;
      default:
        return 'unknown';
    }
  }

}
