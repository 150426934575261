<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<mat-drawer-container>
  <mat-drawer #drawer position="end" class="apply-filter-mat-drawer-height">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
      class="apply-filter"
    >
      <div fxLayout="column" fxFlex="90%" class="apply-filter-header">
        APPLY FILTER
      </div>
      <div fxLayout="column" fxFlex="10%">
        <mat-icon (click)="drawer.close()">close</mat-icon>
      </div>
    </div>
    <div class="apply-filter-body">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <form [formGroup]="filterFormGroup">
          <div
            fxLayout="row"
            fxLayout.xs="column"
            fxLayoutWrap
            fxLayoutGap="16px"
            fxLayoutAlign="start stretch"
            class="form-group form-field-bottom"
          >
            <mat-form-field fxFlex="100%" appearance="outline">
              <mat-label>Batch Status</mat-label>
              <mat-select
                class="form-control"
                formControlName="batchStatus"
                name="batchStatus"
              >
                <mat-option *ngFor="let type of typeList" [value]="type.key"
                  >{{ type.value }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </form>
      </div>

      <div fxLayout="column" fxLayoutAlign="center end">
        <div fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
          <button mat-button class="white-button-style" (click)="restForm()">
            RESET
          </button>
          &nbsp;&nbsp;
          <button
            mat-raised-button
            color="primary"
            class="page-header-button-style"
            (click)="applyAdvanceFilter()"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div class="page-header-bottom">
      <div
        class="page-header"
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
      >
        <div fxLayout="column" fxLayoutGap="5px">
          <div
            fxLayout="column"
            fxLayout.xs="column"
            fxLayoutAlign="start stretch"
          >
            <div class="page-header-text">Batch Transactions</div>
            <div class="page-sub-header-text-1">
              All batch transactions related data can be managed from this page
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="invoice-container"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutAlign="start stretch"
    >
      <div
        fxLayout="column"
        class="totalTransactionsDiv"
        fxLayoutGap="5px"
        fxFlex="100%"
      >
        <mat-tab-group animationDuration="0ms">
          <mat-tab label="ACH">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="container-div"
            >
              <div fxLayout="column" fxFlex="100%">
                <app-data-table
                  [columns]="columns"
                  [data]="data"
                  [isExport]="false"
                  [isLoading]="isLoading"
                  [isLoadingPaging]="isLoadingPaging"
                  [page]="page"
                  [sortBy]="sortBy"
                  [noRecordMessage]="noRecordMessage"
                  [actionDelete]="actionDelete"
                  [showMoreMenu]="true"
                  [allColumns]="allColumns"
                  [selection]="selection"
                  (actionDeleteEvent)="onDelete($event)"
                  [deletedFilter]="deletedFilter"
                  [actionRefresh]="actionRefresh"
                  (actionRefreshEvent)="onRefresh($event)"
                  (pageChangedEvent)="onPageChanged($event)"
                  (sortChangedEvent)="onSortChanged($event)"
                  (onFilterEvent)="onFilter($event)"
                  (advanceFilterEvent)="drawer.toggle()"
                  (deleteBasedOnSelectionEvent)="deleteBasedOnSelection($event)"
                  [filterText]="this.filter?.toString() ?? ''"
                  [advanceFilterCount]="advanceFilterCount"
                  [role]="this.userRole"
                  [isSpacerRequired]="true"
                  [isCreateNewBtn]="true"
                  (navigationToPageEvent)="OpenDialogForCustomerGrp()"
                  (batchViewEvent)="viewBatchTransactions($event)"
                  (onBatchTransactionDeleteEvent)="
                    onBatchTransactionDeleteEvent($event)
                  "
                  (onBatchTransactionCloneEvent)="
                    onBatchTransactionCloneEvent($event)
                  "
                  (onBatchTransactionProcessNowEvent)="
                    onBatchTransactionProcessNowEvent($event)
                  "
                  [isBatch]="true"
                >
                  <!-- (rowClickedEvent)="onRowClick($event)" openFilter(); -->
                </app-data-table>
              </div>
            </div>
          </mat-tab>
          <mat-tab *ngIf="false" label="Credit/Debit">
            <div
              fxLayout="row"
              fxLayout.xs="column"
              fxLayoutAlign="start stretch"
              class="container-div"
            >
              <div fxLayout="column" fxFlex="100%"></div>
            </div>
          </mat-tab>
        </mat-tab-group>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>

<!-- Dialog Template -->
<ng-template #customerGroupDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Create New Batch</b></h4>
  <form [formGroup]="createCustomerGrpFormGroup">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="form-group form-field-bottom"
    >
      <div
        fxLayoutGap="16px"
        fxFlex="100%"
        class="header-content"
        fxLayoutAlign="start center"
      >
        <span class="header-title">Company Name: </span
        ><span>{{ companyName }}</span>
        <span class="header-title">Company ID: </span
        ><span> {{ companyId }}</span>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="form-group form-field-bottom"
    >
      <!-- <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Customer Group</mat-label>
        <input
          matInput
          class="form-control"
          formControlName="CustomerGroupId"
          type="text"
          name="CustomerGroupId"
          placholder="Group Name"
          autocomplete="off"
        />
      </mat-form-field> -->
      <mat-form-field fxFlex="50%" appearance="outline">
        <input
          matInput
          formControlName="customerGroupName"
          [matAutocomplete]="auto"
          placeholder="Customer Group Name (min 3 characters)"
          pattern="^[a-zA-Z0-9 ]*$"
          style="padding-bottom: 1%"
        />
         <button
          matSuffix
          mat-icon-button
          aria-label="Clear"
          (click)="clearSelection()"
        >
          <mat-icon>close</mat-icon>
        </button>
        <button matPrefix mat-icon-button>
          <mat-icon>search</mat-icon>
        </button> 
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="onSelected()"          
        >
          <mat-option *ngIf="isLoading" class="is-loading"
            >Loading...</mat-option
          >
          <ng-container *ngIf="!isLoading && filteredCustomerGroup.length > 0">
            <mat-option
              *ngFor="let customerGroup of filteredCustomerGroup"
              [value]="selectedCustomerGroupName(customerGroup)"
            >
              <div class="name-email-style">
                {{ customerGroup.groupName?.trim() }}
              </div>
              <!-- <div class="name-email-style sub-text">
                {{ customerGroup.email }}
              </div> -->
            </mat-option>
          </ng-container>
          <ng-container
            *ngIf="isShowNoFound && !isLoading && !filteredCustomerGroup.length"
          >
            <mat-option>
              <div class="name-email-style">
                <b>No Records Found</b>
              </div>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
      </mat-form-field>

      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label> Transaction Type</mat-label>
        <mat-select
          class="form-control"
          formControlName="TransactionType"
          name="transactionTypeApplyAll"
          [disabled]="isDisabledTransactionType"
        >
          <mat-option
            *ngFor="let type of transactionTypes"
            [value]="type.value"
          >
            {{ type.displayName }}
          </mat-option>
        </mat-select>
        <mat-error>
          {{
            createCustomerGrpFormGroup.controls["TransactionType"][
              "_errorMessage"
            ]
          }}
        </mat-error>
      </mat-form-field>
    </div>
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="form-group form-field-bottom"
    >
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label class="required">Batch Name</mat-label>
        <input
          matInput
          class="form-control"
          formControlName="BatchName"
          type="text"
          name="BatchName"
          placholder="Batch Name"
          autocomplete="off"
        />
        <mat-error>
          {{
            createCustomerGrpFormGroup.controls["BatchName"]["_errorMessage"]
          }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label class="required">Effective Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          class="form-control"
          formControlName="EffectiveDate"
          placeholder="Select Effective Date"
          autocomplete="off"
          [min]="minDate"
          readonly
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          {{
            createCustomerGrpFormGroup.controls["EffectiveDate"][
              "_errorMessage"
            ]
          }}
        </mat-error>
      </mat-form-field>
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="row"
      mat-dialog-actions
      fxLayoutAlign="center end"
    >
      <button
        class="white-button-style"
        appearance="outline"
        (click)="onCancel()"
        mat-button
      >
        <span>CANCEL</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="createCustomerGrpFormGroup.invalid"
        class="page-header-button-style"
        (click)="AddBatchTransactions()"
      >
        CREATE
      </button>
    </div>
  </form>
</ng-template>

<!-- Dialog Template -->
<ng-template #cloneBatchDialog>
  <h4 mat-dialog-title fxLayoutAlign="center end"><b>Clone Batch</b></h4>
  <form [formGroup]="cloneCustomerGrpFormGroup">
    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="form-group form-field-bottom"
    >
      <div
        fxLayoutGap="16px"
        fxFlex="100%"
        class="header-content"
        fxLayoutAlign="start center"
      >
        <span class="header-title">Company Name: </span
        ><span>{{ companyName }}</span>
        <span class="header-title">Company ID: </span
        ><span> {{ companyId }}</span>
      </div>
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
      fxLayoutGap="16px"
      fxLayoutAlign="start stretch"
      class="form-group form-field-bottom"
    >
      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Batch Name</mat-label>
        <input
          matInput
          class="form-control"
          formControlName="BatchName"
          type="text"
          name="BatchName"
          placeholder="Batch Name"
          autocomplete="off"
        />
        <mat-error>
          {{ cloneCustomerGrpFormGroup.controls["BatchName"]["_errorMessage"] }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="50%" appearance="outline">
        <mat-label>Effective Date</mat-label>
        <input
          matInput
          [matDatepicker]="picker"
          class="form-control"
          formControlName="EffectiveDate"
          placeholder="Select Effective Date"
          autocomplete="off"
          [min]="minDate"
        />
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-error>
          {{
            cloneCustomerGrpFormGroup.controls["EffectiveDate"]["_errorMessage"]
          }}
        </mat-error>
      </mat-form-field>
      <input
        matInput
        class="form-control"
        formControlName="AchNachaBatchId"
        type="hidden"
        name="AchNachaBatchId"
      />
    </div>

    <div
      fxLayout="row"
      fxLayout.xs="row"
      mat-dialog-actions
      fxLayoutAlign="center end"
    >
      <button
        class="white-button-style"
        appearance="outline"
        (click)="onCancel()"
        mat-button
      >
        <span>CANCEL</span>
      </button>
      <button
        mat-raised-button
        color="primary"
        [disabled]="cloneCustomerGrpFormGroup.invalid"
        class="page-header-button-style"
        (click)="CloneBatchTransactions()"
      >
        CLONE
      </button>
    </div>
  </form>
</ng-template>
