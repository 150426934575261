<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>



<div class="backHeader" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
  <mat-label>
    <button mat-icon-button (click)="navigateToBatchTransactions()">
      <mat-icon>arrow_back</mat-icon>
    </button>
    Back to Batch Transactions
  </mat-label>
</div>

<div fxLayout="column" fxLayoutGap="16px">
  <mat-card class="totalTransactionsDiv" fxFlex="100%">
    <div fxLayout="row" fxLayoutGap="16px" fxFlex="100%">
      <div fxLayoutGap="10px" fxFlex="100%" class="header-content" fxLayoutAlign="start center">
        <span class="header-title">Company Name: </span> {{ this.companyName }}
        <span class="header-title" style="padding-left: 50px;">Company ID:</span>{{ this.companyId }}
      </div>
      <!-- <span class="spacer"></span> -->
  <div fxLayout="column" fxLayoutAlign="end end">
    <div fxLayout="row" fxLayout.xs="column" fxLayoutGap="10px" fxFlex="100%">

      
      <button mat-raised-button color="primary" (click)="batchEdit()"  class="page-header-button-style" *ngIf="isBatchModify">
       Edit
      </button>
    </div>
  </div>
    </div>
  </mat-card>
  <mat-card class="totalTransactionsDiv"  fxFlex="100%">
    <div fxLayout="row" fxLayoutGap="16px" fxLayoutAlign="end end"  fxFlex="100%">

      <div class="form-group"  fxFlex="25%">
        <div class="form-header header-title">Customer Group:</div>
        <div class="form-value">{{ this.groupName }}</div>
      </div>
      <div class="form-group"  fxFlex="25%">
        <div class="form-header header-title">Transaction Type:</div>
        <div class="form-value">{{ this.transactionType }}</div>
      </div>

      <div class="form-group" fxFlex="25%">
        <div class="form-header header-title">Batch Name:</div>
        <div class="form-value">{{ this.batchName }}</div>
      </div>

      <div class="form-group" fxFlex="25%">
        <div class="form-header header-title">Effective Date:</div>
        <div class="form-value">{{ this.effectiveDate | date: 'MM/dd/yyyy' }}</div>
      </div>
    </div>
  </mat-card>
  <div fxLayout="row">
    <mat-card class="totalTransactionsDiv custom-mat-card" style="padding:0px" fxFlex="100%">
      <mat-card-header class="mat-card-header">
        <div fxLayoutGap="16px" fxFlex="100%" class="header-content">
          <span style="padding-top:9px;">TOTAL # OF TRANSACTIONS:<span class="transaction-header-title"> {{ getTotalTransactions }}</span> </span>
          <span style="padding-top:9px">TOTAL DEBITS:<span class="transaction-header-title"> ${{ getTotalDebits | number: '1.2-2' }}</span> </span>
          <span style="padding-top:9px">TOTAL CREDITS:<span class="transaction-header-title"> ${{ getTotalCredits | number: '1.2-2' }}</span> </span>
          <span style="display: flex; align-items: center;">
            NEXT CUTOFF TIME:
            <span style="font-weight: bold; margin-left: 7px; text-align: center;">
              <div>{{ nextCutOffTime | date: 'MM/dd/yyyy' }}</div>
              <div style="margin-left: 10px;">{{ nextCutOffTime | date: 'hh:mm a' }} PST</div>
            </span>
          </span>
        </div>
      </mat-card-header>
      <mat-card-content>
      <h3 style="padding:16px; padding-bottom: 0px;margin-bottom: 0px;">Batch 
    </h3>
      <app-data-table [columns]="customerGrpscolumn" [data]="data" [isLoading]="isLoading"
        [noRecordMessage]="noRecordMessage" [allColumns]="allColumns" [isExport]="false" [role]="this.userRole"
        [page]="page" [isFilter]="false" [isSearch]="false" [datatablemargin]="this.datatablemargin" (pageChangedEvent)="onPageChanged($event)" 
        [sortBy]="sortBy" (sortChangedEvent)="onSortChanged($event)" (batchViewEvent) = "openViewNoteDialog($event)" [isViewBatch]="true" [isBatch]="true">
      </app-data-table>
    </mat-card-content>
    </mat-card>
  </div>
</div>

<ng-template #viewNoteDialog>
  <h6 mat-dialog-title fxLayoutAlign="center end"><b>View Note</b></h6>
    <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
    class="description-top">
    <div fxLayout="column" fxFlex="100%" >      
        <div class="textarea-spacing">
            <mat-form-field appearance="outline">              
              <textarea
                autosize
                matInput
                name="description"
                rows="5"   
                [value]="note"            
                readonly
              >
              </textarea>
            </mat-form-field>
        </div> 
    </div>              
</div> 

    <div mat-dialog-actions fxLayoutAlign="end end">
      <button
        class="white-button-style"
        appearance="outline"
        mat-button
        [mat-dialog-close]="true"
      >
        <span>CANCEL</span>
      </button>
    </div>
</ng-template>

