<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage">
</app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToCustomersPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Customers
    </mat-label>
  </div>

  <div
    class="page-header"
    fxLayout="row"
    fxLayout.xs="column"
    fxLayoutAlign="start stretch"
  >
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div *ngIf="isAddCustomer" class="page-header-text">Add Customer</div>
        <div *ngIf="!isAddCustomer" class="page-header-text">Edit Customer</div>
        <div class="page-sub-header-text-1">
          All individual & company related data can be managed from this page
        </div>
      </div>
    </div>
  </div>
</div>

<mat-stepper
  [linear]="isLinearStepper"
  #stepper
  (selectionChange)="stepperChange($event)"
  style="margin: 15px"
>
  <ng-template matStepperIcon="edit">
    <mat-icon>check</mat-icon>
  </ng-template>
  <mat-step [stepControl]="basicDetailsFormGroup">
    <div class="basicDetailsForm">
      <form [formGroup]="basicDetailsFormGroup">
        <ng-template matStepLabel>Basic Details</ng-template>
        <div class="fontBold personalInfoLabel">Personal Information</div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
          style="padding: 20px 0px 10px 0px"
        >
          <mat-radio-group
            fxFlex="100%"
            class="form-control customRadio"
            formControlName="type"
            name="type"
            (change)="onTypeSelected($event)"
            style="font-size: 12px"
          >
            <mat-radio-button
              style="width: 100px; font-size: 15px"
              [value]="false"
              >Individual
            </mat-radio-button>
            <mat-radio-button
              style="width: 100px; font-size: 15px"
              [value]="true"
              >Company
            </mat-radio-button>
          </mat-radio-group>
        </div>
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="25%" *ngIf="!isCompany">
            <mat-form-field appearance="outline">
              <mat-label class="required">First Name</mat-label>
              <input
                matInput
                formControlName="firstName"
                class="form-control"
                name="firstName"
                placeholder="First Name"
                autocomplete="off"
                maxlength="35"
              />
              <mat-error>
                {{
                  basicDetailsFormGroup.controls["firstName"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="69%" *ngIf="isCompany">
            <mat-form-field appearance="outline">
              <mat-label class="required">Company Name</mat-label>
              <input
                matInput
                formControlName="firstName"
                class="form-control"
                name="firstName"
                placeholder="Company Name"
                autocomplete="off"
                maxlength="35"
              />
              <mat-error>
                {{
                  basicDetailsFormGroup.controls["firstName"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="25%" *ngIf="!isCompany">
            <mat-form-field appearance="outline">
              <mat-label class="required">Last Name</mat-label>
              <input
                matInput
                formControlName="lastName"
                class="form-control"
                name="lastName"
                placeholder="Last Name"
                autocomplete="off"
                maxlength="30"
              />
              <mat-error>
                {{
                  basicDetailsFormGroup.controls["lastName"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>

          <div
            fxLayout="column"
            fxLayoutWrap
            fxFlex="25%"
            *ngIf="!isCompany"
          ></div>
          <div
            fxLayout="column"
            fxLayoutWrap
            fxFlex="25%"
            *ngIf="!isCompany"
          ></div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <ngx-intl-tel-input
              [ngClass]="{
                'phone-invaid':
                  basicDetailsFormGroup.controls['phone'].invalid &&
                  basicDetailsFormGroup.controls['phone'].touched,
                'phone-valid': basicDetailsFormGroup.controls['phone'].valid
              }"
              [preferredCountries]="preferredCountries"
              [enableAutoCountrySelect]="true"
              [enablePlaceholder]="true"
              [customPlaceholder]="'Mobile Number'"
              [searchCountryFlag]="true"
              [searchCountryField]="[
                SearchCountryField.Iso2,
                SearchCountryField.Name
              ]"
              [selectFirstCountry]="false"
              [selectedCountryISO]="CountryISO.UnitedStates"
              [phoneValidation]="true"
              [separateDialCode]="separateDialCode"
              [numberFormat]="PhoneNumberFormat.National"
              [value]="phoneNumber"
              name="phone"
              formControlName="phone"
              [maxLength]="maxLength"
              (countryChange)="changePhone($event)"
            >
            </ngx-intl-tel-input>
            <mat-error
              *ngIf="basicDetailsFormGroup.controls['phone'].touched"
              >{{
                basicDetailsFormGroup.controls["phone"]["_errorMessage"]
              }}</mat-error
            >
            <div *ngIf="phonehint" class="phonehint-text">
              eg.: {{ phonehint }}
            </div>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Email Address</mat-label>
              <input
                matInput
                class="form-control"
                formControlName="email"
                name="email"
                placeholder="Email Address"
                autocomplete="off"
                maxlength="50"
                style="padding: 2px"
              />
              <mat-error>
                {{ basicDetailsFormGroup.controls["email"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <div
              fxLayout="row"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              style="margin-top: 6%"
            >
              <div fxLayout="column" fxLayoutWrap>
                <mat-label>Status: </mat-label>
              </div>
              <div fxLayout="column" fxLayoutWrap>
                <mat-slide-toggle
                  formControlName="isActive"
                  name="isActive"
                  class="form-control"
                >
                  <mat-label
                    class="showDetailsValue"
                    [ngStyle]="{
                      ' color': basicDetailsFormGroup.controls['isActive'].value
                        ? 'green'
                        : 'red'
                    }"
                  >
                    {{
                      basicDetailsFormGroup.controls["isActive"].value
                        ? "Active"
                        : "Inactive"
                    }}
                  </mat-label>
                </mat-slide-toggle>
                <mat-error>
                  {{
                    basicDetailsFormGroup.controls["isActive"]["_errorMessage"]
                  }}
                </mat-error>
              </div>
            </div>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="25%"></div>
        </div>

        <div class="fontBold personalInfoLabel">Address</div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Address Line 1</mat-label>
              <input
                matInput
                formControlName="address1"
                class="form-control"
                name="address1"
                placeholder="Address Line 1"
                autocomplete="off"
                maxlength="500"
              />
              <mat-error>
                {{
                  basicDetailsFormGroup.controls["address1"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>Address Line 2</mat-label>
              <input
                matInput
                formControlName="address2"
                class="form-control"
                name="address2"
                placeholder="Address Line 2"
                autocomplete="off"
                maxlength="500"
              />
              <mat-error>
                {{
                  basicDetailsFormGroup.controls["address2"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">City</mat-label>
              <input
                matInput
                formControlName="city"
                class="form-control"
                name="city"
                placeholder="City"
                autocomplete="off"
                maxlength="30"
              />
              <mat-error>
                {{ basicDetailsFormGroup.controls["city"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <mat-select
                class="form-control"
                formControlName="state"
                name="state"
              >
                <mat-option
                  *ngFor="let stateOpt of states"
                  [value]="stateOpt.postalCode"
                  >{{ stateOpt.state }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ basicDetailsFormGroup.controls["state"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="24%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Postal Code</mat-label>
              <input
                matInput
                class="form-control"
                formControlName="zip"
                name="zip"
                placeholder="Postal Code"
                autocomplete="off"
                (focusout)="onZipFocusOut($event)"
              />
              <mat-error>
                {{ basicDetailsFormGroup.controls["zip"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- <div fxLayout="column" fxLayoutWrap fxFlex="75%"></div> -->
        </div>

        <div class="fontBold personalInfoLabel">Allowed IP Addresses</div>
        
        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="32px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
        <div fxLayout="column" fxLayoutWrap fxFlex="25%">
          <mat-form-field appearance="outline">
            <mat-label>IP Address</mat-label>
            <input
              matInput
              formControlName="allowedIpAddress"
              class="form-control"
              name="allowedIpAddress"
              placeholder="Enter IP Address"
              autocomplete="off"
              maxlength="50"              
            />
            <mat-error>
              {{
                basicDetailsFormGroup.controls["allowedIpAddress"]["_errorMessage"]
              }}
            </mat-error>
            <mat-hint class="hint">Maximum of 10 IPs can be added</mat-hint>
          </mat-form-field>
        </div>
        <div fxLayout="column" fxFlex="5%">
          <button mat-raised-button color="primary" style="margin-top: 9px;"(click)="addNewIps()">
            ADD
          </button>
        </div>
        </div>
        <br />
        <label *ngIf="basicDetailsModel?.ipAddressList?.length" class="additional-text"
          >Whitelisted IP Addresses:</label
        >
        <br />
        <mat-chip-list>
          <mat-chip
            class="custom-chip-style"
            *ngFor="let ip of basicDetailsModel?.ipAddressList; index as i"
          >
            <mat-label> {{ ip }} </mat-label>
            <mat-icon class="cancelIcon" (click)="removeNewIps(i)"
              >cancel</mat-icon
            >
          </mat-chip>
        </mat-chip-list>
      </form>
    </div>
    <div class="action-buttons">
      <span class="saveandnext">
        <button
          mat-flat-button
          color="primary"
          class="button-line-height"
          (click)="addCustomerBasicDetails(stepper)"
        >
          NEXT
        </button>
      </span>
      <span style="float: right">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="navigateToCustomersPage()"
        >
          CANCEL
        </button>
      </span>
    </div>
  </mat-step>

  <mat-step [stepControl]="basicDetailsFormGroup">
    <div class="bankAccountForm">
      <form [formGroup]="bankDetailsFormGroup">
        <ng-template matStepLabel>Bank Details</ng-template>
        <div *ngIf="isAddCustomerBank" class="addBankAccountLabel fontBold">
          Add Bank Account
        </div>
        <div *ngIf="!isAddCustomerBank" class="addBankAccountLabel fontBold">
          Update Bank Account
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <mat-form-field appearance="outline">
              <mat-label>Bank Name</mat-label>
              <input
                matInput
                placeholder="Bank Name (min 3 characters)"
                formControlName="bankName"
                [matAutocomplete]="auto"
                maxlength="200"
              />
              <button
                *ngIf="bankDetailsFormGroup.controls['bankName'].value"
                matSuffix
                mat-icon-button
                aria-label="Clear"
                (click)="clearSelection()"
              >
                <mat-icon>close</mat-icon>
              </button>
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option *ngIf="isSearchLoading" class="is-loading"
                  >Loading...</mat-option
                >
                <ng-container
                  *ngIf="!isSearchLoading && bankNameList.length > 0"
                >
                  <mat-option
                    *ngFor="let bankname of bankNameList"
                    [value]="bankname"
                  >
                    {{ bankname }}
                  </mat-option>
                </ng-container>
                <ng-container
                  *ngIf="
                    isShowNoFound && !isSearchLoading && !bankNameList.length
                  "
                >
                  <mat-option>
                    <div class="name-no-record">
                      <b>No Records Found</b>
                    </div>
                  </mat-option>
                </ng-container>
              </mat-autocomplete>
              <mat-error>
                {{ bankDetailsFormGroup.controls["bankName"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Account Number</mat-label>
              <input
                matInput
                formControlName="bankAccountNumber"
                class="form-control"
                name="bankAccountNumber"
                placeholder="Account Number"
                autocomplete="off"
                maxlength="17"
              />
              <mat-error>
                {{
                  bankDetailsFormGroup.controls["bankAccountNumber"][
                    "_errorMessage"
                  ]
                }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <div
              fxLayout="row"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              style="margin-top: 6%"
            >
              <div fxLayout="column" fxLayoutWrap>
                <mat-label>Is Primary: </mat-label>
              </div>
              <div fxLayout="column" fxLayoutWrap>
                <mat-slide-toggle
                  formControlName="isPrimary"
                  name="isPrimary"
                  class="form-control"
                >
                  <mat-label class="showDetailsValue">
                    {{
                      bankDetailsFormGroup.controls["isPrimary"].value
                        ? "Yes"
                        : "No"
                    }}
                  </mat-label>
                </mat-slide-toggle>
                <mat-error>
                  {{
                    bankDetailsFormGroup.controls["isPrimary"]["_errorMessage"]
                  }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Routing Number</mat-label>
              <input
                matInput
                formControlName="routingNumber"
                class="form-control"
                name="routingNumber"
                placeholder="Routing Number"
                autocomplete="off"
                maxlength="9"
              />
              <mat-error>
                {{
                  bankDetailsFormGroup.controls["routingNumber"][
                    "_errorMessage"
                  ]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <mat-form-field appearance="outline">
              <mat-label>Account Type</mat-label>
              <mat-select
                class="form-control"
                formControlName="accountType"
                name="accountType"
              >
                <mat-option *ngFor="let at of accountType" [value]="at.value">
                  {{ at.display }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{
                  bankDetailsFormGroup.controls["accountType"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="33%">
            <div
              fxLayout="row"
              fxLayoutWrap
              fxLayoutGap="40px"
              fxLayoutAlign="start center"
              style="margin-top: 6%"
            >
              <div fxLayout="column" fxLayoutWrap>
                <mat-label>Status: </mat-label>
              </div>
              <div fxLayout="column" fxLayoutWrap>
                <mat-slide-toggle
                  formControlName="status"
                  name="status"
                  class="form-control"
                >
                  <mat-label
                    class="showDetailsValue"
                    [ngStyle]="{
                      ' color': bankDetailsFormGroup.controls['status'].value
                        ? 'green'
                        : 'red'
                    }"
                  >
                    {{
                      bankDetailsFormGroup.controls["status"].value
                        ? "Active"
                        : "Inactive"
                    }}
                  </mat-label>
                </mat-slide-toggle>
                <mat-error>
                  {{ bankDetailsFormGroup.controls["status"]["_errorMessage"] }}
                </mat-error>
              </div>
            </div>
          </div>
        </div>
        <div>
          <br />
          <button
            [disabled]="isBankReset"
            mat-button
            color="primary"
            [ngStyle]="{
              border: isBankReset ? '1px solid #9da1af' : '1px solid #1e4ed8'
            }"
            class="secondaryBtn button-line-height"
            (click)="resetBankForm()"
          >
            RESET
          </button>
          <button
            *ngIf="isAddCustomerBank"
            mat-flat-button
            color="primary"
            class="button-line-height"
            (click)="addCustomerBankDetails()"
          >
            SAVE BANK ACCOUNT
          </button>
          <button
            *ngIf="!isAddCustomerBank"
            mat-flat-button
            color="primary"
            class="button-line-height"
            (click)="addCustomerBankDetails()"
          >
            UPDATE BANK ACCOUNT
          </button>
        </div>
      </form>
    </div>

    <div class="bankTitle fontBold">Banks</div>

    <app-bank-accounts
      *ngIf="customerId"
      [activateDeactButton]="false"
      [bankList]="bankList"
      (editBankEvent)="editBankDetails($event)"
    >
    </app-bank-accounts>

    <div class="action-buttons">
      <span style="float: left">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="previousStep(stepper)"
        >
          PREVIOUS
        </button>
      </span>
      <span class="saveandnext">
        <button
          mat-flat-button
          color="primary"
          class="button-line-height"
          (click)="saveAndNext(stepper)"
        >
          NEXT
        </button>
      </span>
      <span style="float: right">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="navigateToCustomersPage()"
        >
          CANCEL
        </button>
      </span>
    </div>
  </mat-step>

  <mat-step [stepControl]="basicDetailsFormGroup">
    <div class="cardDetailsForm">
      <form [formGroup]="cardDetailsFormGroup">
        <ng-template matStepLabel>Card Details</ng-template>
        <div *ngIf="isAddCustomerCard" class="addBankAccountLabel fontBold">
          Add Card Details
        </div>
        <div *ngIf="!isAddCustomerCard" class="addBankAccountLabel fontBold">
          Update Card Details
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Name On Card</mat-label>
              <input
                matInput
                formControlName="nameOnCard"
                class="form-control"
                name="nameOnCard"
                placeholder="Name On Card"
                autocomplete="off"
                maxlength="100"
              />
              <mat-error>
                {{
                  cardDetailsFormGroup.controls["nameOnCard"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Card Number</mat-label>
              <input
                matInput
                formControlName="cardNumber"
                class="form-control"
                name="cardNumber"
                placeholder="Card Number"
                autocomplete="off"
                (keyup)="getCardImage()"
                maxlength="19"
              />
              <img matSuffix width="34" height="24" src="{{ cardImageName }}" />
              <mat-error>
                {{
                  cardDetailsFormGroup.controls["cardNumber"]["_errorMessage"]
                }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Exp Month</mat-label>
              <mat-select
                class="form-control"
                formControlName="expMonth"
                name="expMonth"
              >
                <mat-option *ngFor="let month of monthList" [value]="month"
                  >{{ month }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ cardDetailsFormGroup.controls["expMonth"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>

          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label class="required">Exp Year</mat-label>
              <mat-select
                class="form-control"
                formControlName="expYear"
                name="expYear"
              >
                <mat-option *ngFor="let year of yearList" [value]="year"
                  >{{ year }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ cardDetailsFormGroup.controls["expYear"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="50%">
            <mat-form-field appearance="outline">
              <mat-label>Address Line</mat-label>
              <textarea
                matInput
                #cardAddress
                maxlength="900"
                name="address"
                class="form-control"
                formControlName="address"
                placeholder="Address Line"
                autocomplete="off"
              ></textarea>
              <mat-hint align="end"
                >{{ cardAddress.value.length }} / 900</mat-hint
              >
              <mat-error>
                {{ cardDetailsFormGroup.controls["address"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="23%">
            <div
              fxLayout="row"
              fxLayoutWrap
              fxLayoutGap="16px"
              fxLayoutAlign="start center"
              style="margin-top: 10%"
            >
              <div fxLayout="column" fxLayoutWrap>
                <mat-label>Status: </mat-label>
              </div>
              <div fxLayout="column" fxLayoutWrap>
                <mat-slide-toggle
                  formControlName="status"
                  name="status"
                  class="form-control"
                >
                  <mat-label
                    class="showDetailsValue"
                    [ngStyle]="{
                      ' color': cardDetailsFormGroup.controls['status'].value
                        ? 'green'
                        : 'red'
                    }"
                  >
                    {{
                      cardDetailsFormGroup.controls["status"].value
                        ? "Active"
                        : "Inactive"
                    }}
                  </mat-label>
                </mat-slide-toggle>
                <mat-error>
                  {{ cardDetailsFormGroup.controls["status"]["_errorMessage"] }}
                </mat-error>
              </div>
            </div>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%"></div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        >
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>City</mat-label>
              <input
                matInput
                formControlName="city"
                class="form-control"
                name="city"
                placeholder="City"
                autocomplete="off"
                maxlength="30"
              />
              <mat-error>
                {{ cardDetailsFormGroup.controls["city"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>State</mat-label>
              <mat-select
                class="form-control"
                formControlName="state"
                name="state"
              >
                <mat-option
                  *ngFor="let stateOpt of states"
                  [value]="stateOpt.postalCode"
                  >{{ stateOpt.state }}
                </mat-option>
              </mat-select>
              <mat-error>
                {{ cardDetailsFormGroup.controls["state"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%">
            <mat-form-field appearance="outline">
              <mat-label>Postal Code</mat-label>
              <input
                matInput
                class="form-control"
                formControlName="zip"
                name="zip"
                placeholder="Postal Code"
                autocomplete="off"
                (focusout)="onCardZipFocusOut($event)"
              />
              <mat-error>
                {{ cardDetailsFormGroup.controls["zip"]["_errorMessage"] }}
              </mat-error>
            </mat-form-field>
          </div>
          <div fxLayout="column" fxLayoutWrap fxFlex="25%"></div>
        </div>

        <div
          fxLayout="row"
          fxLayout.xs="column"
          fxLayoutWrap
          fxLayoutGap="16px"
          fxLayoutAlign="start stretch"
          class="form-group"
        ></div>
        <div>
          <br />
          <button
            [disabled]="isCardReset"
            mat-button
            color="primary"
            [ngStyle]="{
              border: isCardReset ? '1px solid #9da1af' : '1px solid #1e4ed8'
            }"
            class="secondaryBtn button-line-height"
            (click)="resetCardForm()"
          >
            RESET
          </button>
          <button
            *ngIf="isAddCustomerCard"
            mat-flat-button
            color="primary"
            class="button-line-height"
            (click)="addCustomerCardDetails()"
          >
            SAVE CARD DETAILS
          </button>
          <button
            *ngIf="!isAddCustomerCard"
            mat-flat-button
            color="primary"
            class="button-line-height"
            (click)="addCustomerCardDetails()"
          >
            UPDATE CARD DETAILS
          </button>
        </div>
      </form>
    </div>

    <div class="bankTitle fontBold">Cards</div>

    <app-debit-credit-cards
      *ngIf="customerId"
      [customerId]="customerId"
      [activateDeactButton]="false"
      [debitCreditCardsList]="debitCreditCardsList"
      (editCardEvent)="editCardDetails($event)"
    >
    </app-debit-credit-cards>

    <div class="action-buttons">
      <span style="float: left">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="previousStep(stepper)"
        >
          PREVIOUS
        </button>
      </span>
      <span class="saveandnext">
        <button
          mat-flat-button
          color="primary"
          class="button-line-height"
          (click)="saveAndNext(stepper)"
        >
          NEXT
        </button>
      </span>
      <span style="float: right">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="navigateToCustomersPage()"
        >
          CANCEL
        </button>
      </span>
    </div>
  </mat-step>

  <mat-step [stepControl]="basicDetailsFormGroup">
    <ng-template matStepLabel>Summary & Submit</ng-template>
    <!-- <p>You are now done.</p> -->

    <div
      class="summaryPageLabel"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
    >
      <div fxFlex="90%">
        <span class="summaryPageLabel fontBold">Personal Information</span>
      </div>
      <div fxFlex="10%">
        <button
          mat-button
          color="primary"
          class="secondaryBtn floatRight button-line-height"
          (click)="editCustomerDetailsSummary()"
        >
          EDIT
        </button>
      </div>
    </div>
    <app-customer-details
      *ngIf="customerModel"
      [isViewCustomerDetails]="true"
      [isDataManagement]="false"
      [customerModel]="customerModel"
    ></app-customer-details>

    <div
      class="summaryPageLabel"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
    >
      <div fxFlex="90%">
        <span class="fontBold">Bank Details</span>
      </div>
      <div fxFlex="10%">
        <button
          mat-button
          color="primary"
          class="secondaryBtn floatRight button-line-height"
          (click)="editBankDetailsSummary()"
        >
          EDIT
        </button>
      </div>
    </div>
    <app-bank-accounts
      [bankList]="bankList"
      (editBankEvent)="editBankDetails($event)"
    >
    </app-bank-accounts>

    <div
      class="summaryPageLabel"
      fxLayout="row"
      fxLayout.xs="column"
      fxLayoutWrap
    >
      <div fxFlex="90%">
        <span class="fontBold">Card Details</span>
      </div>
      <div fxFlex="10%">
        <button
          mat-button
          color="primary"
          class="secondaryBtn floatRight button-line-height"
          (click)="editCardDetailsSummary()"
        >
          EDIT
        </button>
      </div>
    </div>

    <app-debit-credit-cards
      [customerId]="customerId"
      [debitCreditCardsList]="debitCreditCardsList"
      (editCardEvent)="editCardDetails($event)"
    >
    </app-debit-credit-cards>

    <div class="action-buttons">
      <span style="float: left">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="previousStep(stepper)"
        >
          PREVIOUS
        </button>
      </span>
      <span class="saveandnext">
        <button
          mat-flat-button
          color="primary"
          class="button-line-height"
          (click)="submitCustomer()"
        >
          SUBMIT
        </button>
      </span>
      <span style="float: right">
        <button
          mat-button
          color="primary"
          class="secondaryBtn button-line-height"
          (click)="navigateToCustomersPage()"
        >
          CANCEL
        </button>
      </span>
    </div>
  </mat-step>
</mat-stepper>
