<app-loading-spinner [isLoading]="isLoading" [loadMessage]="loadMessage"></app-loading-spinner>

<div class="page-header-bottom">
  <div class="backHeader">
    <mat-label>
      <button mat-icon-button (click)="navigateToBackPage()">
        <mat-icon>arrow_back</mat-icon>
      </button>
      Ticket Listing
    </mat-label>
  </div>
  <div  class="page-header" fxLayout="row" fxLayout.xs="column" fxLayoutAlign="start stretch">
    <div fxLayout="column" fxLayoutGap="5px">
      <div fxLayout="column" fxLayout.xs="column" fxLayoutAlign="start stretch">
        <div class="page-header-text">Create A Ticket</div>
        <div class="page-sub-header-text-1">
          All ticket related information can be viewed from this page
        </div>
      </div>
    </div>
  </div>
</div>


<form [formGroup]="createTicketFormGroup">
    <div class="tickets-overview">
      <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutAlign="center start" class="new-ticket-box">
          NEW TICKET
      </div>
      <div class="ticket-form">
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" >                  
              <div fxLayout="column" fxFlex="75%">
                  <mat-form-field appearance="outline">
                      <mat-label class="required">Subject</mat-label>
                      <input matInput formControlName="subject" name="subject" class="required" placeholder="Subject Line*" />           
                  </mat-form-field>
                  <mat-error>
                      {{ createTicketFormGroup.controls["subject"]["_errorMessage"] }}
                  </mat-error>
              </div>
          </div>
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start center" >
              <div fxLayout="column" fxFlex="25%">
                  <mat-form-field appearance="outline">
                      <mat-label class="required">Category</mat-label>
                      <mat-select class="form-control" placeholder="Select Category*"
                          formControlName="category" name="category">
                          <mat-option *ngFor="let category of categoryType" [value]="category.key">
                          {{ category.value }}
                          </mat-option>
                      </mat-select>
                  </mat-form-field>
                  <mat-error>
                      {{ createTicketFormGroup.controls["category"]["_errorMessage"] }}
                  </mat-error>
              </div>
              <div fxLayout="column" fxFlex="25%">                    
                  <mat-checkbox formControlName="isAffectBusiness">Does this affect business</mat-checkbox>                    
              </div>
          </div> 
          <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px" fxLayoutAlign="start stretch"
              class="description-top">
              <div fxLayout="column" fxFlex="100%" >
                  <mat-label class="required">Description :</mat-label>      
                  <div class="textarea-spacing">
                      <mat-form-field appearance="outline">              
                        <textarea
                          autosize
                          matInput
                          name="description"
                          rows="5"
                          placeholder="Enter Text*"
                          formControlName="description"
                        >
                        </textarea>
                        <mat-error>
                          {{
                              createTicketFormGroup.controls["description"]["_errorMessage"]
                          }}
                        </mat-error>
                      </mat-form-field>
                  </div> 
              </div>              
          </div> 
            <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="36px"  fxLayoutAlign="start stretch" class="description-top">                
                  <div fxLayout="column" class="upload-box">
                    <label
                    color="primary"
                    class="file-select">
                      UPLOAD ATTACHMENT
                      <input id="inputAttachment" type="file" class="file-input" (change)="onFileSelected($event)" 
                      accept=".pdf,.doc,.docx,.txt,.rtf,.xls,.xlsx,.csv,.ppt,.pptx,.jpg,.jpeg,.png,.gif,.zip,.rar,.log"
                      #fileUpload placeholder="Upload Attachment">     
                    </label>                                           
                  </div>                                                            
            </div> 
            <mat-hint class="hint-style">Maximum Upload file size is 5 MB*. Can Upload upto 5 attachments.</mat-hint>  
            <div fxLayout="row" fxLayout.xs="column" *ngFor="let file of uploadedFilelist" fxLayoutAlign="start stretch" class="description-top">
              <div fxLayout="column" fxLayout.xs="row" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch" fxFlex="100%" class="file-upload-box">
                <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxLayoutAlign="start stretch">
                  <div fxLayout="column" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="6px" fxLayoutAlign="start stretch" fxFlex="80%">
                      <div class="file-name">
                          <span> {{ file.name }}</span>
                      </div>
                      <div class="file-size">
                          <span> {{ file.size | formatFileSize}}</span>
                      </div>
                  </div> 
                  <div fxLayout="column" fxLayout.xs="column"  fxLayoutAlign="end end"  fxFlex="20%">
                      <img class="deleteImg" matTooltip="Remove Attachment" alt="deleteimg"
                        src="{{ delete_const | showIcon }}" (click)="onFileDeleted(file)" />
                  </div>
                </div>
                <div  fxLayout="row"  fxLayout.xs="column" fxLayoutWrap fxLayoutGap="16px" fxFlex="100%" fxLayoutAlign="start stretch" class="file-progress-bar">
                </div>
              </div> 
            </div>                                  
      </div>
    </div>
    <div fxLayout="column" fxLayoutAlign="center end">
      <div
        fxLayout="row"
        fxLayout.xs="column"
        fxLayoutAlign="start stretch"
        class="saveandcancel"
      >
        <button
          mat-stroked-button
          color="primary"
          class="white-button-style"
          (click)="navigateToBackPage()"
        >
          CANCEL
        </button>
        &nbsp;&nbsp;
        <button
          mat-raised-button
          color="primary"
          class="page-header-button-style"
          (click)="submitTicket()"
        >
          SUBMIT
        </button>
      </div>
    </div>
</form>

