import {
  required,
  prop,
  maxLength,
  pattern,
} from '@rxweb/reactive-form-validators';

export class UpdateBatchModel {
  @prop()
  achNachaBatchId: string;

  @required()
  @maxLength({ value: 60 })
  @pattern({ expression: { Alphanumeric: /^[a-zA-Z0-9 ()]*$/ } })
  @pattern({ expression: { No_Space: /.*\S.*/ } })
  batchName: string;

  @prop()
  groupName: string;

  @prop()
  @required()
  effectiveDate: Date;

  @prop()
  totalTransactions: string;

  @prop()
  totalCreditAmount: string;

  @prop()
  totalDebitAmount: string;

  @prop()
  batchStatus: string;

  @prop()
  isActive: boolean = true;

  @prop()
  transactionType: string;

  @prop()
  batchTransactionDetails: BatchTransactionDetails[];
}

class BatchTransactionDetails {
  @prop()
  achNachaBatchTransactionId: string;

  @prop()
  achNachaBatchId: string;

  @prop()
  customerId: string;

  // @prop()
  // accountNumber: string;

  @prop()
  eventType: string;

  @prop()
  amount: number;

  @prop()
  batchTransactionStatus: string;

  @prop()
  notes: string;

  @prop()
  effectiveDate: Date;

  @prop()
  isActive: boolean;
}
