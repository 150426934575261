import { pattern, prop, required } from '@rxweb/reactive-form-validators';

export class UpdateTicketModel {  

  @prop()
  @required()
  @pattern({
    expression: { Alpha_Hyphen_Period_Space: /^(?!\s)(?![\s\S]*\s$)[a-zA-Z0-9.\-\s]*$/,}})
  @pattern({ expression: { No_Space: /.*\S.*/ }} )
  comment: string;

  @prop()
  attachments: any;

  @prop()
  firstName:string;

  @prop()
  lastName:string;

}