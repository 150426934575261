import { SelectionModel } from '@angular/cdk/collections';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatDrawer } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { ToastrService } from 'ngx-toastr';
import { catchError, map, Observable, of } from 'rxjs';
import { ConfirmationDialogComponent } from 'src/app/sharedModules/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  DBFilterOptions,
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { CommonService } from 'src/app/sharedModules/services/common/common.service';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { TicketFilterModel } from 'src/app/sharedModules/models/ticket-filter.model';
import { HttpClient } from '@angular/common/http';
import { TicketService } from 'src/app/featureModules/services/ticket.service';
import { SubmitReviewDialogComponent } from 'src/app/sharedModules/components/dialogs/submit-review-dialog/submit-review-dialog.component';
import { TicketStatusConstant } from 'src/app/sharedModules/constants/ticket-status-constant';

const REFRESH_PAGING_TIMEOUT_MS = 350;
export type ListFilterObj = { [key: string]: string | number | boolean };
export type ListFilter = string | ListFilterObj;

@Component({
  selector: 'app-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class TicketListComponent implements OnInit, OnDestroy {
  @Input()
  listRefreshEvent = new EventEmitter();

  selection = new SelectionModel<TableColumn>(true, []);

  @Input()
  filterEvent = new EventEmitter();

  @Input()
  deletedFilterEvent = new EventEmitter();

  @Input()
  recurringFilterEvent = new EventEmitter(); // for transaction list

  @Input()
  actionDelete = false;

  @Input()
  actionRefresh = false;

  @Input()
  actionPrint = false;

  @Input()
  maxLength: string;

  @Input()
  noRecordMessage: string;

  data: any;
  isLoading = false;
  loadMessage: string = '';
  isLoadingPaging = false;
  filter: ListFilter = '';
  recurringFilter = false;
  deletedFilter = false;
  IsActive = null;
  countFilter: ListFilter;
  gotDataResponse = false;
  filteredCustomer: any[] = [];
  minLengthTerm = 3;
  isShowNoFound: boolean = false;
  customerId: string;
  searchTextPattern = '^[a-zA-Z0-9@.-_ ]*$';
  userRole: any;

  private timeout: number;

  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };

  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  columns: TableColumn[] = [
    { displayName: 'TICKET ID', name: 'ticketNumber', width: '10%' },
    { displayName: '', name: 'icon', width: '4%' },
    { displayName: 'USER DETAILS', name: 'name', width: '16%' },
    { displayName: 'PRIORITY', name: 'priority', width: '10%' },
    { displayName: 'DESCRIPTION', name: 'description', width: '23%' },
    { displayName: 'DATE & TIME', name: 'entryDate', width: '12%' },
    { displayName: 'STATUS', name: 'ticketStatus', width: '10%' },
    { displayName: 'ACTION', name: 'rating', width: '10%' },
  ];

  statusList = [
    { key: 'All', value: 'ALL' },
    { key: 'Open', value: 'OPEN' },
    { key: 'In Process', value: 'IN PROCESS' },
    { key: 'Assigned', value: 'ASSIGNED' },
    { key: 'Withdrawn', value: 'WITHDRAWN' },
    { key: 'Reopened', value: 'REOPENED' },
    { key: 'Closed', value: 'CLOSED' },
    { key: 'Information Required', value: 'INFO REQ' },
  ];

  priorityList = [
    { key: 'All', value: 'ALL' },
    { key: 'LOW', value: 'LOW' },
    { key: 'MEDIUM', value: 'MEDIUM' },
    { key: 'HIGH', value: 'HIGH' },
  ];

  allColumns: TableColumn[];

  filterFormGroup: FormGroup;

  advanceFilters: string;
  advanceFiltersList:any;
  advanceFilterCount:number;

  @ViewChild('drawer') drawer: MatDrawer;

  constructor(
    private router: Router,
    private ticketService: TicketService,
    private formBuilder: RxFormBuilder,
    private _commonService: CommonService,
    private dialog: MatDialog,
    private _toastrService: ToastrService,
    private http: HttpClient
  ) {
    this.allColumns = this.columns;

    this.filter = this._commonService.getListDataFromLocalStorage(
      'ticketListGridFilter',
      'ticket',
      ''
    );
    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];

    this.page = {
      pageIndex: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ticketListGridPageIndex',
          'ticket',
          0
        )
      ),
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ticketListGridPageSize',
          'ticket',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ticketListGridSortActive',
        'ticket',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ticketListGridSortDirection',
        'ticket',
        'desc'
      ),
    };

    this.advanceFilters = this._commonService.getListDataFromLocalStorage(
      'ticketListAdvanceFilter',
      'ticket',
      ''
    );
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    let filterFormData = JSON.parse(
      this._commonService.getListDataFromLocalStorage(
        'ticketListAdvanceFilterForm',
        'ticket',
        JSON.stringify(new TicketFilterModel())
      )
    );

    let filterModel = new TicketFilterModel();
    Object.assign(filterModel, filterFormData);
    this.filterFormGroup = this.formBuilder.formGroup(filterModel);
  }

  onDelete(id: string) {}

  onRefresh(id: string) {}

  loadData(
    filter: ListFilter,
    sortBy: string,
    sortDirection: string,
    page: number,
    pageSize: number,
    advanceFilters: string
  ) {
    this.isLoading = true;
    this.drawer?.close();
    this.selection.clear();
    this.ticketService
      .getAllTickets(
        filter?.toString(),
        sortBy,
        sortDirection,
        page,
        pageSize,
        this.advanceFilters
      )
      .subscribe(
        (data) => {
          this.isLoading = false;
          this.drawer?.close();
          if (data.data != null) {
            this.data = data.data.tickets;
            this.page.length = data.data.totalRecords;
            var list = [];
            this.data?.map((item) => {
              let record = {
                id: item.id,
                ticketNumber: item.ticketNumber,
                firstName: item.firstName,
                lastName: item.lastName,
                name: item.enteredBy,
                email: item.enteredBy,
                priority: item.priority,
                description: item.description,
                entryDate: item.entryDate,
                ticketStatus: item.ticketStatus,
                review: item.review,
                rating: item.rating ?? 0,
              };
              list.push(record);
            });
            this.data = list;
          } else {
            this.data = [];
          }
        },
        (error) => {
          this.isLoading = false;
          this._toastrService.error(
            'Something went wrong, Please contact administrator!'
          );
        }
      );
  }

  onError(message: string) {}

  protected onDeletedFilter(event: boolean = false) {
    this.deletedFilter = !!event;
    this.page.pageIndex = 0;
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  // Server side Filtering
  public onFilter(event: ListFilter = '') {
    if (this.filter !== event) {
      this.page.pageIndex = 0;

      this.filter = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        this.filter.toString(),
        'ticketListGridFilter',
        'ticket'
      );
    }
  }

  // Server side Pagination
  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize
    ) {
      this.page = event;
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.pageSize.toString(),
        'ticketListGridPageSize',
        'ticket'
      );
      this._commonService.setListDataToLocalStorage(
        event.pageIndex.toString(),
        'ticketListGridPageIndex',
        'ticket'
      );
    }
  }

  // Server side Sorting
  onSortChanged(event: SortChanged) {
    const column1: TableColumn = this.columns[event.active];
    var column = this.columns.find((obj) => {
      return obj.name === event.active ? obj : null;
    });
    if (column && column.dontSort) {
      return;
    }
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;
      this.sortByMapping();
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );

      this._commonService.setListDataToLocalStorage(
        event.active,
        'ticketListGridSortActive',
        'ticket'
      );
      this._commonService.setListDataToLocalStorage(
        event.direction,
        'ticketListGridSortDirection',
        'ticket'
      );
    }
  }
  sortByMapping() {
    switch (this.sortBy.active) {
      case 'name':
        this.sortBy.active = 'firstName';
        break;
      case 'ticketNumber':
        this.sortBy.active = 'entryDate';
        break;
    }
  }

  protected refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.countFilter = filter;
    this.page.length = 0;
    this.timeout = window.setTimeout(() => {
      this._refreshPaging(filter, count, options);
    }, REFRESH_PAGING_TIMEOUT_MS);
  }

  private _refreshPaging(
    filter: ListFilter,
    count: (body: any) => Observable<any>,
    options: DBFilterOptions
  ) {
    this.isLoadingPaging = true;
    const countOptions = Object.assign({}, options);
    delete countOptions.$orderBy;
    delete countOptions.$skip;
    delete countOptions.$top;
    delete countOptions.$expand;

    countOptions.$count = true;
    if (this.maxLength) {
      countOptions.$top = Number(this.maxLength);
    }

    count(countOptions)
      .pipe(
        map((response: number) => {
          if (filter === this.countFilter) {
            this.page.length = response > 0 ? response : 0;
            this.isLoading = this.gotDataResponse == false ? true : false;
          }
        }),
        catchError((err) => {
          this.onError(err.message);
          this.isLoading = false;
          return of([]);
        })
      )
      .subscribe(() => {
        this.isLoadingPaging = false;
      });
  }

  public onWithdrawClick(event: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: '200px',
      data: {
        delete: false,
        buttonName: 'WITHDRAW',
        successMessage: 'Are you sure you want to withdraw this ticket?',
        subSuccessMessage:
          'Clicking on withdraw button will remove ticket permanently',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed: boolean) => {
      if (confirmed) {
        this.isLoading = true;
        this.ticketService.withdrawTicket(event.id).subscribe((data) => {
          this.isLoading = false;
          if (data.data != null) {
            this._toastrService.error('Ticket Withdrawn Successfully!');
            this.loadData(
              this.filter,
              this.sortBy.active,
              this.sortBy.direction,
              this.page.pageIndex,
              this.page.pageSize,
              this.advanceFilters
            );
          }
        });
      }
    });
  }

  public onwriteRewiewClick(event: any) {
    this.ticketService.dialogRef = this.dialog.open(
      SubmitReviewDialogComponent,
      {
        height: '370px',
        width: '634px',
        data: {
          ticketNumber: event.ticketNumber,
          id: event.id,
        },
      }
    );
    this.ticketService.dialogRef
      .afterClosed()
      .subscribe((confirmed: boolean) => {
        this.loadData(
          this.filter,
          this.sortBy.active,
          this.sortBy.direction,
          this.page.pageIndex,
          this.page.pageSize,
          this.advanceFilters
        );
      });
  }

  public onticketClick(event: any) {
    if (
      event.ticketStatus == TicketStatusConstant.Reopened ||
      event.ticketStatus == TicketStatusConstant.Open ||
      event.ticketStatus == TicketStatusConstant.Assigned ||
      event.ticketStatus == TicketStatusConstant.InformationRequired
    ) {
      this.router.navigate(['ticket/ticket-update/', event.id]);
    } else if (
      event.ticketStatus == TicketStatusConstant.Closed ||
      event.ticketStatus == TicketStatusConstant.Withdrawn
    ) {
      this.router.navigate(['ticket/ticketdetails/', event.id]);
    } else {
      this._toastrService.warning('Please Contact Your Administrator');
    }
  }

  public restForm() {
    if (this.filterFormGroup) {
      this.filterFormGroup.reset();     
      this.filterFormGroup = this.formBuilder.formGroup(
        new TicketFilterModel()
      );
      this.filterFormGroup.patchValue({ticketStatus :'ALL' , priority :'ALL'});
    }
  }

  public openFilter() {
    if (!this._commonService._constantData) {
      this._commonService.getJSON('constants');
    }
  }

  public applyAdvanceFilter() {
    let data = this.filterFormGroup.value;
    // this.filter = '';
    this.page = {
      pageIndex: 0,
      pageSize: parseInt(
        this._commonService.getListDataFromLocalStorage(
          'ticketListGridPageSize',
          'ticket',
          10
        )
      ),
      length: 100,
      previousPageIndex: 0,
    };

    this.sortBy = {
      active: this._commonService.getListDataFromLocalStorage(
        'ticketListGridSortActive',
        'ticket',
        'entryDate'
      ),
      direction: this._commonService.getListDataFromLocalStorage(
        'ticketListGridSortDirection',
        'ticket',
        'desc'
      ),
    };
    this.advanceFilters = '';

    if (data.periodStartDate && data.periodEndDate) {
      this.advanceFilters +=
        'cdate:' +
        getSafeIsoDateString(data.periodStartDate) +
        'to' +
        getSafeIsoDateString(data.periodEndDate) +
        '|';
    }

    if (data.ticketStatus && data.ticketStatus !='ALL') {
      this.advanceFilters += 'ticketStatus:' + data.ticketStatus + '|';
    }

    if (data.priority && data.priority !='ALL') {
      this.advanceFilters += 'priority:' + data.priority + '|';
    }

    this._commonService.setListDataToLocalStorage(
      this.advanceFilters.toString(),
      'ticketListAdvanceFilter',
      'ticket'
    );
    
    this.advanceFiltersList = this.advanceFilters.split('|');
    this.advanceFilterCount = this.advanceFiltersList.length - 1;

    this._commonService.setListDataToLocalStorage(
      JSON.stringify(this.filterFormGroup.value),
      'ticketListAdvanceFilterForm',
      'ticket'
    );

    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
  }

  ngOnInit() {
    this.loadData(
      this.filter,
      this.sortBy.active,
      this.sortBy.direction,
      this.page.pageIndex,
      this.page.pageSize,
      this.advanceFilters
    );
    this.listRefreshEvent.subscribe(() => {
      this.loadData(
        this.filter,
        this.sortBy.active,
        this.sortBy.direction,
        this.page.pageIndex,
        this.page.pageSize,
        this.advanceFilters
      );
    });
    this.filterEvent.subscribe(this.onFilter.bind(this));
    this.deletedFilterEvent.subscribe(this.onDeletedFilter.bind(this));

    /* this.filterFormGroup.controls['createdBy'].valueChanges
    .pipe(
      filter((res) => {
        this.isShowNoFound = false;
        if (res) {
          return (
            this.filterFormGroup.controls['createdBy'].valid &&
            res !== null &&
            res.length >= this.minLengthTerm
          );
        } else {
          this.filteredCustomer = [];
        }
      }),
      distinctUntilChanged(),
      debounceTime(10),
      tap(() => {
        this.isShowNoFound = false;
        this.filteredCustomer = [];
        this.isLoading = true;
      }),
      switchMap((value) =>
        this._customerService.SearchCustomers(value, true).pipe(
          finalize(() => {
            this.isLoading = false;
          })
        )
      )
    )
    .subscribe((data: any) => {
      if (data.data && !data.data.length) {
        this.filteredCustomer = [];
        this.isShowNoFound = true;
      } else {
        this.filteredCustomer = data.data;
        this.isShowNoFound = false;
        this.customerId = this.filteredCustomer[0].customerId;
      }
    });  */
  }

  selectedCustomerName(customer: any) {
    return customer.firstName?.trim() + ' ' + customer.lastName?.trim();
  }

  onSelected() {
    var selected = (this.filteredCustomer = this.filteredCustomer.filter(
      (customer: any) =>
        this.filterFormGroup.get('createdBy').value ==
        customer.firstName?.trim() + ' ' + customer.lastName?.trim()
    ));
    if (selected && selected[0])
      this.filterFormGroup.get('createdById').setValue(selected[0].customerId);
    this.filterFormGroup
      .get('createdBy')
      .setValue(selected[0].firstName + ' ' + selected[0].lastName);
  }

  clearSelection() {
    this.filterFormGroup.get('createdBy').setValue(null);
    this.filterFormGroup.get('createdById').setValue(null);
    this.filteredCustomer = [];
  }

  ngOnDestroy() {
    this.filterEvent.unsubscribe();
    this.listRefreshEvent.unsubscribe();
    clearTimeout(this.timeout);
    if(!this.router.url.includes('ticket'))
    localStorage.removeItem('ticket');
  }

  getAllInvoices(): Observable<any> {
    return this.http.get<any>('/assets/invoices.json');
  }
}
