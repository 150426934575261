import {
  Component,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { TableColumn } from 'src/app/sharedModules/models/data-table.model';
import {
  PageChanged,
  SortChanged,
} from 'src/app/sharedModules/models/pagination.model';
import { AddCustomerFromCustomergroupComponent } from '../../../customer-related/add-customer-from-customergroup/add-customer-from-customergroup.component';
import { BatchTransactionsService } from 'src/app/blockModules/services/batch-transactions.service';
import { UpdateBatchModel } from 'src/app/sharedModules/models/update-batch.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { getSafeIsoDateString } from 'src/app/sharedModules/utils/dates';
import { debounceTime, distinctUntilChanged, filter, finalize, switchMap, tap } from 'rxjs';
import { CustomerService } from 'src/app/blockModules/services/customers.service';

@Component({
  selector: 'app-batch-transaction-details',
  templateUrl: './batch-transaction-details.component.html',
  styleUrls: ['./batch-transaction-details.component.scss'],
})
export class BatchTransactionDetailsComponent implements OnInit {
  isLoading: boolean = false;
  loadMessage: string = 'Please wait ....';
  groupName: string = '';
  groupId: string = '';
  transactionType: string = '';
  customerGrpscolumn: TableColumn[] = [
    { displayName: 'CUSTOMER', name: 'name', width: '15%' },
    { displayName: 'ID', name: 'customerId', width: '30%' },
    { displayName: 'ACCT #', name: 'accountNumber', width: '8%' },
    { displayName: 'TYPE', name: 'achTransactionType', width: '11%', className:'row-height-transaction-type' },
    { displayName: 'AMOUNT', name: 'defaultAmount', width: '11%', className:'row-height-transaction-type' },
    { displayName: 'STATUS', name: 'batchTransactionStatus', width: '14%' },
    { displayName: 'NOTES', name: 'notes', width: '10%' },
    { displayName: 'INCLUDE', name: 'isInclude', width: '10%' },
  ];
  data: any;
  @Input()
  noRecordMessage: string;
  allColumns: TableColumn[];
  userRole: any;
  page: PageChanged = {
    pageIndex: 0,
    pageSize: 10,
    length: 100,
    previousPageIndex: 0,
  };
  datatablemargin: string = '0px';
  batchId: string;
  companyId: string = '';
  companyName: string = '';
  selectedCustomerList: any[] = [];

  applyToAllForm: FormGroup;
  updateNoteForm : FormGroup;
  @ViewChild('applyToAllDialog') applyToAllDialog!: TemplateRef<any>;
  @ViewChild('addCustomerDialog') addCustomerDialog!: TemplateRef<any>;
  @ViewChild('viewNoteDialog') viewNoteDialog!: TemplateRef<any>;
  filteredCustomer: any[] = [];
  isShowNoFound: boolean = false;
  customerSearchFormGroup: FormGroup;
  minLengthTerm = 3;
  isDisabled: boolean = true;
  isReadonlyType: boolean=true;
  
  batchName: string = '';
  // effectiveDate: string = getSafeIsoDateString(
  //   new Date(new Date().getFullYear(), new Date().getMonth(), 1)
  // );
  effectiveDate: Date = new Date();
  nextCutOffTime: Date = new Date();
  sortBy: SortChanged = {
    active: 'entryDate',
    direction: 'desc',
  };

  updateBatchFormGroup: FormGroup;
  updateBatchModel: UpdateBatchModel;

  transactionTypesGrid = [
    { value: 'CREDIT', displayName: 'Credit Only' },
    { value: 'DEBIT', displayName: 'Debit Only' },
  ];

  minEffectiveDate = new Date();
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _toastrService: ToastrService,
    private customerService: CustomerService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private batchTransactionsService: BatchTransactionsService,
    private formBuilder: RxFormBuilder
  ) {
    this.allColumns = this.customerGrpscolumn;

    var userProfile = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userProfile['role'];
  }

  transactionTypes = [
    { value: 'CREDIT', displayName: 'Credit Only' },
    { value: 'DEBIT', displayName: 'Debit Only' },
    { value: 'CREDIT & DEBIT', displayName: 'Credit & Debit' },
  ];

  ngOnInit(): void {
    
    this.activatedRoute.paramMap.subscribe((params) => {
      this.batchId = params.get('batchId');
    });

    this.updateBatchModel = new UpdateBatchModel();
    this.updateBatchFormGroup = this.formBuilder.formGroup(
      this.updateBatchModel
    );

    this.applyToAllForm = this.fb.group({
      transactionType: ['', Validators.required],
      defaultAmount: ['', [Validators.required]],
    });

    this.customerSearchFormGroup = this.fb.group({
      customerName: ['', Validators.required],
      customerId: [],
    });

    this.loadData();

    this.customerSearchFormGroup.controls['customerName'].valueChanges
      .pipe(
        filter((res) => {
          this.isShowNoFound = false;
          if (res) {
            return (
              this.customerSearchFormGroup.controls['customerName'].valid &&
              res !== null &&
              res.length >= this.minLengthTerm
            );
          } else {
            this.filteredCustomer = [];
          }
        }),
        distinctUntilChanged(),
        debounceTime(1000),
        tap(() => {
          this.isShowNoFound = false;
          this.filteredCustomer = [];
          this.isLoading = true;
        }),
        switchMap((value) =>
          this.customerService.SearchCustomers(value, true).pipe(
            finalize(() => {
              this.isLoading = false;
            })
          )
        )
      )
      .subscribe((data: any) => {
        if (data.data && !data.data.length) {
          this.isShowNoFound = true;
          this.filteredCustomer = [];
        } else {
          this.isShowNoFound = false;
          this.filteredCustomer = data.data;
        }
      });

    this.companyId = localStorage.getItem('CompanyId') || '';
    this.companyName = localStorage.getItem('CompanyName') || '';
    
    this.updateBatchFormGroup.controls['groupName'].disable();
    this.updateNoteForm = this.fb.group({
      description: ['']
    });
  }
  
  navigateToBatchTransactions() {
    this.router.navigate(['batchTransactions']);
  }
 
  loadData() {
    if (this.batchId) {
      this.getAllCustomerByBatchId(this.batchId);
    }
  }

  onPageChanged(event: PageChanged) {
    if (
      this.page.pageIndex !== event.pageIndex ||
      this.page.pageSize !== event.pageSize ||
      this.groupName != ''
    ) {
      this.page = event;
      const startIndex = this.page.pageIndex * this.page.pageSize;
      const endIndex = startIndex + this.page.pageSize;
      this.data = this.selectedCustomerList.slice(startIndex, endIndex);
    }
  }

  openApplyToAllDialog() {
    const dialogRef = this.dialog.open(this.applyToAllDialog);
  }

  onCancel() {
    this.customerSearchFormGroup.reset();
    this.applyToAllForm.reset();
    this.dialog.closeAll();
  }

  onApply() {
    if (this.applyToAllForm.valid) {
      const transactionType = this.applyToAllForm.get('transactionType')?.value;
      const defaultAmount = this.applyToAllForm.get('defaultAmount')?.value;
      if (
        this.transactionType.toLowerCase() === 'credit & debit' ||
        this.transactionType.toLocaleLowerCase() ===
          transactionType.toLowerCase()
      ) {
        this.data.forEach((record) => {
          if (record.isInclude && record.accountNumber) {
            record.achTransactionType = transactionType;
            record.defaultAmount = defaultAmount;
          }
        });
        this.applyToAllForm.reset();
        this.dialog.closeAll();
      } else {
        this._toastrService.error(
          'Selected ACH transaction type does not align with the batch transaction type'
        );
      }
    }
  }

  openCustomerDialog() {
    this.dialog.open(this.addCustomerDialog, {
      height: '220px',
      width: '500px',
    });
  }

  openNewCustomerDialog(customerId: any = null) {
    const dialogRef = this.dialog.open(AddCustomerFromCustomergroupComponent, {
      height: '570px',
      width: '800px',
      data: { customerId },
    });

    dialogRef.afterClosed().subscribe((customer: any) => {
      if (customer?.customerId) {
        const newCustomer = {
          achNachaBatchTransactionId: null,
          firstName: customer.firstName,
          lastName: customer.lastName,
          customerId: customer.customerId,
          accountNumber: customer.accountNumber,
          achTransactionType:
            this.transactionType.toLowerCase() === 'credit'
              ? 'CREDIT'
              : 'DEBIT',
          defaultAmount: 0.00,
          batchTransactionStatus: 'PENDING',
          notes: null,
          isInclude: !!customer.accountNumber,
        };

        this.data = [newCustomer, ...this.data];
        this.selectedCustomerList = this.data;
        this.page.length = this.selectedCustomerList.length;
        this.dialog.closeAll();
      }
    });
  }

  clearSelection() {
    this.customerSearchFormGroup.get('customerName').setValue(null);
    this.customerSearchFormGroup.get('customerId').setValue(null);
    this.filteredCustomer = [];
  }

  onSelected() {
    var selected = (this.filteredCustomer = this.filteredCustomer.filter(
      (customer: any) =>
        this.customerSearchFormGroup.get('customerName').value ==
        customer.firstName?.trim() + ' ' + customer.lastName?.trim()
    ));
    if (selected && selected[0])
      this.customerSearchFormGroup
        .get('customerId')
        .setValue(selected[0].customerId);
  }

  selectedCustomerName(customer: any) {
    let lastName =
      customer.lastName?.trim() == undefined ? '' : customer.lastName?.trim();
    return customer.firstName?.trim() + ' ' + lastName;
  }

  getAllCustomerByBatchId(batchId: any) {
    this.isLoading = true;
    this.batchTransactionsService
      .getAllCustomerByBatchId(batchId)
      .subscribe((response) => {
        this.isLoading = false;
        if (response.data != null) {
          this.groupName = response.data.customerGroupName;
          this.groupId = response.data.customerGroupId;
          this.transactionType = response.data.groupTransactionType.toUpperCase();
          this.batchName = response.data.batchName;
          this.effectiveDate = response.data.effectiveDate;
          this.nextCutOffTime = response.data.nextCutOffTime;
          this.data = response.data.batchTransactionDetails;
          this.selectedCustomerList = this.data;
          this.page.length = this.data.length;
          this.onPageChanged(this.page);
          this.updateBatchFormGroup.patchValue({
            achNachaBatchId: this.batchId || '',
            effectiveDate: this.effectiveDate || new Date()
          });
        } else {
          this.data = [];
          this._toastrService.error(response.errorMessage);
        }
      }),
      (error) => {
        this.data = [];
        this.isLoading = false;
        this._toastrService.error(error.errorMessage);
      };
  }

  get getTotalTransactions() {
    return this.data?.length || 0;
  }

  get getTotalDebits() {
    return this.data?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'debit')
                   .reduce((sum, record) => sum + record.defaultAmount, 0) || 0;
  }

  get getTotalCredits() {
    return this.data?.filter(record => record.isInclude && record.achTransactionType.toLowerCase() === 'credit')
                  .reduce((sum, record) => sum + record.defaultAmount, 0) || 0;
  }

  onSortChanged(event: SortChanged) {
    if (
      this.sortBy.active !== event.active ||
      this.sortBy.direction !== event.direction
    ) {
      this.sortBy = event;

      if (this.data) {
        const { active, direction } = this.sortBy;

        const sortedList = [...this.data].sort((a, b) => {
          let valueA = a[active];
          let valueB = b[active];

          if (active === 'name') {
            valueA = `${a.firstName || ''} ${a.lastName || ''}`.trim();
            valueB = `${b.firstName || ''} ${b.lastName || ''}`.trim();
          }

          if (valueA === undefined || valueA === null || valueA === '')
            return direction === 'asc' ? -1 : 1;
          if (valueB === undefined || valueB === null || valueB === '')
            return direction === 'asc' ? 1 : -1;

          if (typeof valueA === 'string' && typeof valueB === 'string') {
            return direction === 'asc'
              ? valueA.localeCompare(valueB)
              : valueB.localeCompare(valueA);
          } else if (typeof valueA === 'number' && typeof valueB === 'number') {
            return direction === 'asc' ? valueA - valueB : valueB - valueA;
          } else {
            const strA = String(valueA);
            const strB = String(valueB);
            return direction === 'asc'
              ? strA.localeCompare(strB)
              : strB.localeCompare(strA);
          }
        });

        this.data = sortedList;
      }
    }
  }

  updateBatch(status: any) {
    this.isLoading = true;
    if (this.updateBatchFormGroup.valid) {
      if (this.data) {
        this.updateBatchModel.batchTransactionDetails = this.data.map(
          (customer) => ({
            achNachaBatchTransactionId: customer.achNachaBatchTransactionId,
            achNachaBatchId: this.updateBatchModel.achNachaBatchId,
            // firstName: customer.firstName,
            // lastName: customer.lastName,
            customerId: customer.customerId,
            // accountNumber: customer.accountNumber,
            eventType: customer.achTransactionType,
            amount: customer.defaultAmount,
            // batchTransactionStatus: customer.batchTransactionStatus,
            notes: customer.notes,
            effectiveDate: this.updateBatchModel.effectiveDate,
            IsActive: customer.isInclude
          })
        );
      }

      this.updateBatchFormGroup.patchValue({
        batchStatus: status,
        // cutOffTime: this.nextCutOffTime,
        // customerGroupId: this.groupId,
        batchTransactionDetails: this.updateBatchModel.batchTransactionDetails,
        totalDebitAmount: this.getTotalDebits,
        totalCreditAmount: this.getTotalCredits,
        totalTransactions: this.getTotalTransactions,
      });

      this.batchTransactionsService
        .UpdateBatch(this.updateBatchFormGroup.value, this.batchId)
        .subscribe(
          (response) => {
            this.isLoading = false;

            if (response.data != null) {
              this._toastrService.success(response.data.message);
              this.navigateToBatchTransactions();
            } else {
              this._toastrService.error(response.errorMessage);
            }
          },
          (error) => {
            this.isLoading = false;
            this._toastrService.error(
              'Something went wrong, Please contact administrator!'
            );
          }
        );
    } else {
      this.updateBatchFormGroup.markAllAsTouched();
      //this.data = sortedList;
    }
  }

  insertSelectedCustomer() {
    const selectedCutsomerId =
      this.customerSearchFormGroup.get('customerId').value;
    if (selectedCutsomerId) {
      const isCustomerAlreadyExits = this.data.some(
        (customer) => customer.customerId === selectedCutsomerId
      );
      if (isCustomerAlreadyExits) {
        this._toastrService.error('Selected customer already exists');
      } else {
        const selectedCustomer = this.filteredCustomer.find(
          (customer: any) => customer.customerId === selectedCutsomerId
        );

        const customer = {
          achNachaBatchTransactionId: null,
          firstName: selectedCustomer.firstName,
          lastName: selectedCustomer.lastName,
          customerId: selectedCustomer.customerId,
          accountNumber: selectedCustomer.accountNumber,
          achTransactionType:
            this.transactionType.toLowerCase() === 'credit'
              ? 'CREDIT'
              : 'DEBIT',
          defaultAmount: 0.00,
          batchTransactionStatus: 'PENDING',
          notes: null,
          isInclude: !!selectedCustomer.accountNumber,
        };

        this.data = [customer, ...this.data];
        this.selectedCustomerList = this.data;
        this.page.length = this.selectedCustomerList.length;
        this.dialog.closeAll();
      }
      this.clearSelection();
    }
    this.isLoading = false;
  }
  openViewNoteDialog(data : any){
    let customerRecord = this.data?.find(record => record.customerId == data);
    if(customerRecord ){
      this.updateNoteForm = this.fb.group({
        description: [customerRecord.notes],
        customerId :[data]
      });
    const viewNotedialogRef = this.dialog.open(this.viewNoteDialog,{
      height: '280px',
      width: '350px',
    });

    viewNotedialogRef.afterClosed().subscribe((result) => {
      console.log('Dialog result:', result);
    });
  }
  }
  updateNote(){
      if(!this.updateNoteForm.invalid){
        const customerId = this.updateNoteForm.get('customerId')?.value;
        const description = this.updateNoteForm.get('description')?.value;
        var foundIndex = this.data.findIndex(x => x.customerId == customerId);
        this.data[foundIndex].notes = description;
        this.updateNoteForm.reset();
        this.dialog.closeAll();
      }
  }
  changeEffectiveDate() {
    if(this.updateBatchModel.effectiveDate) {
      this.nextCutOffTime = this.updateBatchModel.effectiveDate;
    }
  }
}
